import { useRef } from 'react'
import {
  CollectionReference,
  DocumentReference,
  Query,
} from 'uni-firebase/firestore'

const useMemoByIsEqual = <T>(
  value: T | undefined,
  isEqual: (a: T, b: T) => boolean
) => {
  const memoedValue = useRef<T | undefined>(value)

  if (
    !(
      (value === undefined && memoedValue.current === undefined) ||
      (value !== undefined &&
        memoedValue.current !== undefined &&
        isEqual(value, memoedValue.current))
    )
  ) {
    memoedValue.current = value
  }

  return memoedValue.current
}

export const useMemoDocumentReference = <T>(
  ref: DocumentReference<T> | undefined
) => {
  return useMemoByIsEqual(ref, (a, b) => a.isEqual(b))
}

export const useMemoCollectionReference = <T>(
  ref: CollectionReference<T> | undefined
) => {
  return useMemoByIsEqual(ref, (a, b) => a.isEqual(b))
}

export const useMemoQuery = <T>(query: Query<T> | undefined) => {
  return useMemoByIsEqual(query, (a, b) => a.isEqual(b))
}
