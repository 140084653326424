import React from 'react'

type CSProps = {
  width?: string | number
  height?: string | number
}
export const MinutesMaker = (props: CSProps) => (
  <svg
    width={props.width || '1024'}
    height={props.height || '1024'}
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    strokeLinejoin="round"
    strokeMiterlimit="2"
    clipRule="evenodd"
    viewBox={'0 0 ' + props.width + ' ' + props.height || '0 0 24 24'}
  >
    <path d="M0,0H24V24H0Z" fill="none" />
    <path
      d="M12,3A3,3,0,0,0,9,6v6a3,3,0,0,0,6,0V6A3,3,0,0,0,12,3Zm0-2a5,5,0,0,1,5,5v6A5,5,0,0,1,7,12V6a5,5,0,0,1,5-5ZM2.192,13.962l1.962-.393a8,8,0,0,0,15.692,0l1.962.393a10,10,0,0,1-19.616,0Z"
      fill="#2845a3"
    />
  </svg>
)
