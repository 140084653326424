import styled from 'styled-components'

const ButtonArea = styled.div`
  margin: 10px;
  align-self: flex-start;

  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  background: #c4c4c4;
  border-radius: 50%;

  position: relative;

  cursor: pointer;

  &::before,
  &::after {
    position: absolute;
    top: calc(50% - 5px);
    left: calc(50% - 1px);
    border-radius: 1px;
    width: 2px;
    height: 10px;
    content: '';
    background-color: #fff;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`
export default ButtonArea
