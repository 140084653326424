import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { App } from 'src/api/firebase'
import firebase from 'firebase/compat/app'
import {
  Data,
  getFormalizedPathname,
  Subscription,
  UserModel,
  UserPrivateReadonly,
} from '@gijirokukun/shared'
import { useDocument } from 'react-firebase-hooks/firestore'
import {
  User,
  UserInfoReadonly,
  UserPrivateWritable,
} from '@gijirokukun/shared'
import { digest } from 'src/api/digest'
import { GetPersonalInformationPopup } from 'src/components/Organisms/Popup/GetPersonalInformationPopup'
import { useAuthCheck } from 'src/hooks/useAuthCheck'
import { useRouter } from 'src/api/router'
import { routes } from 'src/constants/routes'
import { Plan } from '@gijirokukun/shared'
import { SubscriptionInfo } from '@gijirokukun/shared'
import storage from 'src/api/storage'
import {
  anonymousLogin,
  BotIntegrationStatus,
  useAuthInformation,
} from 'src/models/user'

export const AuthContext = createContext<{
  auth: firebase.User | null | undefined
  resetUser: () => void
  openedGetUserDetailsPopup: boolean
  plan: Plan | undefined
  canShowPayerInformation: boolean | undefined
  payerSubscriptionInfo: SubscriptionInfo<Data<Subscription>> | null | undefined
  payerUserDoc: Data<User> | undefined
  userInfoReadonly: Data<UserInfoReadonly> | undefined
  userPrivateReadonly: Data<UserPrivateReadonly> | undefined
  userPrivateWritable: Data<UserPrivateWritable> | undefined
  isTrial: boolean | null | undefined
  currentMemberOrganization:
    | {
        organizationId: string
        name: string
      }
    | null
    | undefined
  doesUserJoinedSomeTeamAsNonOwner: boolean | undefined
  isOrganizationOwner: boolean | undefined
  isBotNameChangeOptionEnabled: boolean | undefined
  isIPAddressLimitOptionEnabled: boolean | undefined
  isUsageExportOptionEnabled: boolean | undefined
  isAdmin: boolean | undefined
  isBetaTester: boolean | undefined
  botIntegrationStatus: BotIntegrationStatus
}>(
  // 必ずAuthProviderが提供される
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-explicit-any
  undefined as any
)

type AuthProviderProps = React.PropsWithChildren<{
  __onlyTestInitialUser__?: firebase.User
}>
const AuthProvider = (props: AuthProviderProps) => {
  const router = useRouter()
  const [user, setUser] = useState<firebase.User | undefined>(
    props.__onlyTestInitialUser__
  )
  const [loginChecked, setLoginChecked] = useState<boolean>(false)

  useEffect(() => {
    if (typeof window !== 'undefined' && user && !user.isAnonymous) {
      void digest(user.uid).then((hashedUserId) => {
        App.analytics.setUserId(hashedUserId)
      })
    }
  }, [user])

  useEffect(() => {
    const unsub = App.auth.onIdTokenChanged((userChanged) => {
      if (userChanged) {
        setUser(userChanged)
        storage.add('signedInAndEmailVerified', userChanged.emailVerified)
      } else {
        storage.add('signedInAndEmailVerified', false)
      }
    })
    return () => {
      unsub()
    }
  }, [])

  useEffect(() => {
    const unsub = App.auth.onAuthStateChanged((userChanged) => {
      console.debug(
        `Auth state change ${user?.uid ?? 'None'} -> ${
          userChanged?.uid ?? 'None'
        }`
      )

      // TODO: session storageのクリア

      if (userChanged) {
        setUser(userChanged)
        setLoginChecked(true)
      } else {
        setUser(undefined)
        setLoginChecked(true)

        // 議事録にいて、ログインしていなければ、ゲストユーザーとしてログインする。
        if (getFormalizedPathname(location.pathname) === routes.comments) {
          void App.auth
            .setPersistence(
              typeof window === 'undefined'
                ? firebase.auth.Auth.Persistence.NONE
                : firebase.auth.Auth.Persistence.SESSION
            )
            .then(() => {
              anonymousLogin(() => {})
            })
        }
      }
    })
    return () => {
      unsub()
    }
  }, [])

  const resetUser = useCallback(() => {
    setUser(undefined)
    setLoginChecked(false)
  }, [])

  const [userDoc] = useDocument<User>(
    user ? UserModel.getDocRef(user.uid) : undefined
  )

  const isUserDocCreated = userDoc?.exists ?? false

  const auth =
    loginChecked === false
      ? undefined
      : user
      ? isUserDocCreated === false
        ? undefined
        : user
      : null

  const { openedGetUserDetailsPopup, setOpenedGetUserDetailsPopup } =
    useAuthCheck({
      user: auth,
      resetUser,
    })

  const {
    userInfoReadonly,
    userPrivateReadonly,
    userPrivateWritable,
    isTrial,
    plan,
    canShowPayerInformation,
    payerSubscriptionInfo,
    payerUserDoc,
    currentMemberOrganization,
    doesUserJoinedSomeTeamAsNonOwner,
    isOrganizationOwner,
    isBotNameChangeOptionEnabled,
    isIPAddressLimitOptionEnabled,
    isUsageExportOptionEnabled,
    botIntegrationStatus,
  } = useAuthInformation(auth)

  return (
    <AuthContext.Provider
      value={{
        auth,
        resetUser,
        openedGetUserDetailsPopup,
        plan,
        canShowPayerInformation,
        payerSubscriptionInfo,
        payerUserDoc,
        userInfoReadonly,
        userPrivateReadonly,
        userPrivateWritable,
        isTrial,
        currentMemberOrganization,
        doesUserJoinedSomeTeamAsNonOwner,
        isOrganizationOwner,
        isBotNameChangeOptionEnabled,
        isIPAddressLimitOptionEnabled,
        isUsageExportOptionEnabled,
        isAdmin: userInfoReadonly?.is_admin,
        isBetaTester: userInfoReadonly?.is_beta_tester,
        botIntegrationStatus,
      }}
    >
      {openedGetUserDetailsPopup &&
        router.pathname !== routes.price &&
        router.pathname !== routes.purchase && (
          <GetPersonalInformationPopup
            close={() => setOpenedGetUserDetailsPopup(false)}
          />
        )}
      {props.children}
    </AuthContext.Provider>
  )
}

/**
 * 認証ユーザーを返す
 *
 * ログインしていなくても、必ずゲストユーザーが返ってくる
 *
 * @returns ユーザー
 */
const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
