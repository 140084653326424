import { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'src/api/router'
import { App } from 'src/api/firebase'
import { routes } from 'src/constants/routes'
import firebase from 'firebase/compat/app'
import { withCallbackURL } from 'src/hooks/useCallbackURL'
import { getFormalizedPathname } from '@gijirokukun/shared'
import { UserModel } from '@gijirokukun/shared'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { OrganizationModel } from '@gijirokukun/shared'
import {
  OrganizationMember,
  UserInfoReadonly,
  UserPrivateWritable,
} from '@gijirokukun/shared'
import { useToasts } from 'react-toast-notifications'
import { logout } from 'src/models/user'
import storage from 'src/api/storage'
import { isSupportedBrowser } from 'src/constants/support'
import { usePlan } from 'src/models/plan'
import { Plan } from '@gijirokukun/shared'
import { isProduction } from 'src/config'
import { useDocument } from 'src/hooks/useDocument'
import { useUserBelongedOrganization } from 'src/models/user'

const TEST_USER_EMAIL_REGEX = isProduction ? undefined : /^.+@example\.com$/

export const isTestAccount = (user: firebase.User) => {
  if (isProduction) return false
  if (user.email === null) return false
  if (TEST_USER_EMAIL_REGEX === undefined) return false
  return TEST_USER_EMAIL_REGEX.test(user.email)
}

/**
 * 未ログインやゲストからページを保護するかどうかのリスト
 */
const guestProtectedPaths: {
  [K in (typeof routes)[keyof typeof routes]]: boolean
} = {
  '/': false,
  '/home': true,
  '/rooms': true,
  '/rooms/new': true,
  '/teams': true,
  '/team': true,
  '/auth/login': false,
  '/auth/verify': false,
  '/auth/forgot': false,
  '/auth/signup': false,
  '/auth/logout': false,
  '/auth/multifactor': true, // ２段階認証はログイン後に行う
  '/comments': false,
  '/terms': false,
  '/privacy': false,
  '/security': false,
  '/analytics': false,
  '/help': false,
  '/price': true,
  '/account/purchase': true,
  '/account/purchased': true,
  '/account/info': true,
  '/account/unsubscribeEmail': true,
  '/account/questionnaire': true,
  '/organization/members': true,
  '/organization/info': true,
  '/auth/link/zoom': true,
  '/auth/link/teams': true,
  '/auth/link/google': false,
  '/schedules': true,
  '/words': true,
  '/admin/orgs': true,
  '/admin/orgs/new': true,
  '/admin/orgs/view': true,
  '/admin/operations': true,
}
const guestProtectedPathList = Object.entries(guestProtectedPaths)
  .filter(([_, t]) => t)
  .map(([path, _]) => path)

/**
 * アプリ画面かどうかのリスト
 */
const isAppPaths: {
  [K in (typeof routes)[keyof typeof routes]]: boolean
} = {
  '/': false,
  '/home': true,
  '/rooms': true,
  '/rooms/new': true,
  '/teams': true,
  '/team': true,
  '/auth/login': false,
  '/auth/verify': false,
  '/auth/forgot': false,
  '/auth/signup': false,
  '/auth/logout': false,
  '/auth/multifactor': false,
  '/comments': true,
  '/terms': false,
  '/privacy': false,
  '/security': false,
  '/analytics': false,
  '/help': false,
  '/price': true,
  '/account/purchase': true,
  '/account/purchased': false,
  '/account/info': true,
  '/account/unsubscribeEmail': false,
  '/account/questionnaire': false,
  '/organization/members': true,
  '/organization/info': true,
  '/auth/link/zoom': false,
  '/auth/link/teams': false,
  '/auth/link/google': false,
  '/schedules': true,
  '/words': true,
  '/admin/orgs': true,
  '/admin/orgs/new': true,
  '/admin/orgs/view': true,
  '/admin/operations': true,
}
const isAppPathList = Object.entries(isAppPaths)
  .filter(([_, t]) => t)
  .map(([path, _]) => path)

export const useAuthCheck = ({
  user,
  resetUser,
}: {
  user: firebase.User | null | undefined
  resetUser: () => void
}) => {
  const router = useRouter()

  const [userInfoReadonly, loadingUserInfoReadonly] =
    useDocumentData<UserInfoReadonly>(
      user ? UserModel.getInfoReadonlyDocRef(user.uid) : undefined
    )

  const [userPrivateWritable, loadingUserPrivateWritable] =
    useDocumentData<UserPrivateWritable>(
      user ? UserModel.getPrivateWritableDocRef(user.uid) : undefined
    )

  const [currentOrganization, loadingCurrentOrganization] =
    useUserBelongedOrganization(user?.uid)
  const [currentOrganizationInfoWritable] = useDocument(
    currentOrganization
      ? OrganizationModel.getOrganizationInfoWritableRef(
          OrganizationModel.getOrganizationRef(
            currentOrganization.organizationId
          )
        )
      : undefined
  )
  const authUserAsOrganizationMemberRef = useMemo(
    () =>
      userInfoReadonly?.belonged_organization_ref && user
        ? OrganizationModel.getMembersRef(
            OrganizationModel.ref().doc(
              userInfoReadonly.belonged_organization_ref.id
            )
          ).doc(user.uid)
        : undefined,
    [userInfoReadonly?.belonged_organization_ref, user]
  )
  const [authUserAsOrganizationMember] = useDocumentData<OrganizationMember>(
    authUserAsOrganizationMemberRef
  )

  const { addToast } = useToasts()

  const [openedGetUserDetailsPopup, setOpenedGetUserDetailsPopup] =
    useState(false)

  const [plan] = usePlan(user?.uid)
  useEffect(() => {
    if (plan && plan.isPaidUser) {
      App.analytics.logEvent('is_paid_user')
    }
  }, [plan])

  // ユーザーの状況に合わせて適切にリダイレクトを行う
  // 優先度順に並べている
  // FIXME: 多すぎて無限リダイレクトなどを防ぐのが難しいのでどうにかして整理する
  useEffect(() => {
    // ! この関数内はリダイレクト前後で呼ばれるので、locationやrouterを使わず引数のpathを使う必要がある
    const checkRoutingAndJumpIfNeeded = async (path: string) => {
      // ロード中はスキップ
      if (
        user === undefined ||
        (user &&
          (loadingCurrentOrganization ||
            (currentOrganization &&
              currentOrganizationInfoWritable === undefined)))
      ) {
        return
      }

      const url = new URL(path, location.origin) // location.originは常に一定であるはず
      const pathname = getFormalizedPathname(url.pathname)
      const isProtedted = guestProtectedPathList.includes(pathname)
      const inApp = isAppPathList.includes(pathname)
      const inAdmin = path.startsWith('/admin/')
      const currentUserPrivateWritable = user
        ? (await UserModel.getPrivateWritableDocRef(user.uid).get()).data()
        : undefined
      const plan = userInfoReadonly
        ? Plan.getPlanFromDoc(userInfoReadonly)
        : undefined

      // 組織の無効なユーザーは即ログアウトさせる
      if (inApp && authUserAsOrganizationMember?.active === false) {
        console.debug('Invalid organization user, logout.')
        await logout(resetUser, router, routes.landing)
        return
      }

      // AL社内管理者以外がadminページに着たらログイン画面に飛ばす
      if (inAdmin && userInfoReadonly && userInfoReadonly.is_admin !== true) {
        void router.push(routes.login)
      }

      // ログインしていないのにプロテクトされたページにいる場合ログイン画面に飛ばす
      if (isProtedted) {
        if (user === null || user?.isAnonymous === true) {
          if (pathname === routes.price) {
            // FIXME: router.pushだと価格表まで飛んでくれなかった
            location.href = `${routes.landing}#価格`
          } else {
            void router.push(withCallbackURL(routes.login, url.href))
          }
          return
        }
      }

      // メール認証が済んでいないユーザーがアプリに入ろうとしたら認証画面に飛ばす
      if (isSupportedBrowser && inApp) {
        if (user && user.email != null && !user.emailVerified) {
          // テストアカウントなら飛ばない
          if (!isTestAccount(user)) {
            void router.push(withCallbackURL(routes.verify, url.href))
            return
          }
        }
      }

      // 多要素認証が要求されている組織でかつ多要素認証が設定されていなければ多要素認証ページに飛ばす
      if (
        inApp &&
        currentOrganizationInfoWritable &&
        user &&
        currentOrganizationInfoWritable.isRequireMultiFactorAuthentication &&
        user.multiFactor.enrolledFactors.length === 0
      ) {
        await router.push(withCallbackURL(routes.multifactor, url.href))
        return
      }

      // サインアップ直後のユーザーはPurchase画面に飛ばす
      if (
        inApp &&
        user &&
        (user.emailVerified || isTestAccount(user)) &&
        plan?.isInvalid === true &&
        userInfoReadonly?.prev_planV2Type === undefined
      ) {
        if (
          pathname === routes.purchase ||
          // キャンセルするとprice画面に飛ぶので
          pathname === routes.price
        ) {
          return
        } else {
          console.debug('go to purchase page.')
          await router.push({
            pathname: routes.purchase,
            query: {
              plan: 'PersonalStandard',
            },
          })
          return
        }
      }

      // 無効なユーザーがゲストが入れないアプリ内の画面に入ろうとしたらPrice画面に飛ばす
      if (inApp && isProtedted && plan?.isInvalid === true) {
        if (pathname === routes.price || pathname === routes.accountInfo) {
          return
        } else {
          console.debug('Invalid user, go to price page.')
          await router.push(routes.price)
          return
        }
      }

      // アンケート以外の画面にいてアプリ内にいるログイン済みユーザーでユーザーアンケートを行っていないならアンケート画面に飛ばす
      if (
        isSupportedBrowser &&
        // アンケート以外の画面
        pathname !== routes.questionnaire &&
        // 購入画面以外の画面　サインアップ後にPersonal Standardトライアルに飛ばす過程でここを経由するため除外する
        pathname !== routes.price &&
        // アプリ内
        inApp &&
        // ログイン済みユーザー
        user &&
        !user.isAnonymous &&
        // Invalidではない
        plan &&
        plan.isInvalid === false &&
        // ユーザーアンケートを行っていない
        currentUserPrivateWritable &&
        !currentUserPrivateWritable.questionnairePageOpened
      ) {
        void router.push({
          pathname: routes.questionnaire,
          query: {
            callback: url.href,
            fallback: url.href,
          },
        })
        return
      }
    }

    void checkRoutingAndJumpIfNeeded(router.asPath)

    router.events.on('routeChangeComplete', checkRoutingAndJumpIfNeeded)
    return () => {
      router.events.off('routeChangeComplete', checkRoutingAndJumpIfNeeded)
    }
  }, [
    user,
    router,
    authUserAsOrganizationMember,
    loadingUserPrivateWritable,
    userInfoReadonly,
    userPrivateWritable,
    addToast,
    resetUser,
    currentOrganization,
    currentOrganizationInfoWritable,
    loadingCurrentOrganization,
  ])

  // ログイン済み非組織ユーザーで個人情報が要求されていたなら入力ポップアップを表示する
  useEffect(() => {
    if (
      isSupportedBrowser &&
      // アプリ内でのみ表示させる
      isAppPathList.includes(getFormalizedPathname(router.pathname)) &&
      user?.isAnonymous === false &&
      userPrivateWritable?.needPersonalInformation === true &&
      // Invalidではない
      plan &&
      plan.isInvalid === false &&
      // 組織のユーザーではないか
      !loadingUserInfoReadonly &&
      userInfoReadonly?.belonged_organization_ref == null
    ) {
      if (!openedGetUserDetailsPopup) {
        App.analytics.logEvent('account_personalInformation_popup_open')
      }
      setOpenedGetUserDetailsPopup(true)
    } else {
      setOpenedGetUserDetailsPopup(false)
    }
  }, [
    openedGetUserDetailsPopup,
    router.pathname,
    user?.isAnonymous,
    userPrivateWritable,
  ])

  // （ホーム画面にリダイレクトするのではなく）LPにアクセスしたユーザー数を計測する
  useEffect(() => {
    if (user !== undefined) {
      if (
        router.pathname === '/' &&
        (user == null || user.isAnonymous === true) // ※ユニークユーザー数はAnalyticsで分かるので、匿名でログイン済みでも記録して問題ない
      ) {
        App.analytics.logEvent('landing_user')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user !== undefined])

  return {
    openedGetUserDetailsPopup,
    setOpenedGetUserDetailsPopup,
  }
}
