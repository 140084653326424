import React from 'react'
import Popup, {
  PopupForm,
  PopupFormContent,
  PopupSubmit,
  PopupText,
} from '../../Molecules/Popup/Popup'
import {
  Center,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  VStack,
} from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useAuth } from 'src/contexts/Auth'
import { App } from 'src/api/firebase'
import styled from 'styled-components'
import { FaExclamationCircle } from 'react-icons/fa'
import { FormInput } from 'src/components/Molecules/Form/FormInput'
import { UserModel } from '@gijirokukun/shared'
import {
  phoneNumberRegex,
  phoneNumberRegexFailedMessage,
} from '@gijirokukun/shared'
import { call } from 'src/api/firebase'

export const PopupContent = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const getPersonalInformationSchema = yup.object({
  username: yup.string().required('氏名を入力してください。'),
  organizationOrPersonal: yup
    .string()
    .matches(/^(organization|personal)$/, {
      message: '必ず選択してください。',
    })
    .nullable(),
  organizationName: yup.string().when('organizationOrPersonal', {
    is: 'organization',
    then: (schema) => schema.required('組織名を入力してください。'),
  }),
  phoneNumber: yup
    .string()
    .required('電話番号を入力してください。')
    .matches(phoneNumberRegex, {
      message: phoneNumberRegexFailedMessage,
    }),
})

export type GetPersonalInformationFormInputs = {
  username: string
  phoneNumber: string
} & (
  | {
      organizationOrPersonal: 'organization'
      organizationName: string
    }
  | {
      organizationOrPersonal: 'personal'
    }
)
// unionが表現できない
// yup.InferType<
//   typeof getPersonalInformationSchema
// >

export const GetPersonalInformationPopup: React.FC<{
  close: () => void
}> = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<GetPersonalInformationFormInputs>({
    resolver: yupResolver(getPersonalInformationSchema),
  })
  const { auth: user } = useAuth()

  const organizationOrPersonal = watch('organizationOrPersonal', undefined)

  const submit = async (formInputs: GetPersonalInformationFormInputs) => {
    if (user == null) {
      return
    }
    App.analytics.logEvent('account_personalInformation_popup_submit')

    // 遅いので完了を待たずに、クライアント側でもフラグを立てる
    void call('submitPersonalInformation')(formInputs)
    // privateWritableは必ず初期化されるが、初期化される前に書き込まれるかもしれないのでsetしている
    await UserModel.getPrivateWritableDocRef(user.uid).set(
      {
        needPersonalInformation: false,
      },
      { merge: true }
    )

    props.close()
  }

  return (
    <Popup
      title="利用者情報の登録"
      layer={11000000}
      clickAway={false}
      style={{ width: '550px' }}
    >
      <PopupForm>
        <PopupContent
          style={{
            marginTop: '35px',
            marginBottom: '35px',
            marginLeft: '0px',
            marginRight: '0px',
          }}
        >
          <VStack spacing="1rem" px={{ base: 0, md: '5rem' }}>
            <FormControl
              isRequired
              isInvalid={errors.username?.message != null}
              w="400px"
            >
              <FormLabel size="sm" variant="grid">
                氏名
              </FormLabel>
              <FormInput
                data-test="getPersonalInformation-form-input-username"
                variant="filled"
                autoComplete="name"
                placeholder="氏名"
                {...register('username')}
              />
              <FormErrorMessage>
                <FormErrorIcon>
                  <FaExclamationCircle color="warning" />
                </FormErrorIcon>
                {errors.username?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isRequired
              isInvalid={errors.organizationOrPersonal?.message != null}
              w="400px"
            >
              <FormLabel size="sm" variant="grid">
                所属
              </FormLabel>
              <RadioGroup w="278px">
                <HStack>
                  <Radio
                    hidden
                    {...register('organizationOrPersonal')}
                    value=""
                    checked
                  ></Radio>
                  <Radio
                    data-test="getPersonalInformation-form-input-organization"
                    {...register('organizationOrPersonal')}
                    value="organization"
                  >
                    組織
                  </Radio>
                  <Radio
                    data-test="getPersonalInformation-form-input-personal"
                    {...register('organizationOrPersonal')}
                    value="personal"
                  >
                    個人
                  </Radio>
                </HStack>
              </RadioGroup>
              <FormErrorMessage>
                <FormErrorIcon>
                  <FaExclamationCircle color="warning" />
                </FormErrorIcon>
                {errors.organizationOrPersonal?.message}
              </FormErrorMessage>
            </FormControl>
            {organizationOrPersonal === 'organization' && (
              <FormControl
                isRequired
                isInvalid={(errors as any).organizationName?.message != null}
                w="400px"
              >
                <FormLabel size="sm" variant="grid">
                  組織名
                </FormLabel>
                <FormInput
                  data-test="getPersonalInformation-form-input-organizationName"
                  variant="filled"
                  autoComplete="organization"
                  placeholder="組織名"
                  {...register('organizationName')}
                />
                <FormErrorMessage>
                  <FormErrorIcon>
                    <FaExclamationCircle color="warning" />
                  </FormErrorIcon>
                  {(errors as any).organizationName?.message}
                </FormErrorMessage>
              </FormControl>
            )}
            <FormControl
              isRequired
              isInvalid={errors.phoneNumber?.message != null}
              w="400px"
            >
              <FormLabel size="sm" variant="grid">
                電話番号
              </FormLabel>
              <FormInput
                data-test="getPersonalInformation-form-input-phoneNumber"
                variant="filled"
                autoComplete="organization"
                placeholder="電話番号"
                {...register('phoneNumber')}
              />
              <FormErrorMessage>
                <FormErrorIcon>
                  <FaExclamationCircle color="warning" />
                </FormErrorIcon>
                {errors.phoneNumber?.message}
              </FormErrorMessage>
            </FormControl>
          </VStack>
        </PopupContent>
        <PopupSubmit
          data-test="getPersonalInformation-form-submit"
          type="submit"
          onClick={handleSubmit(submit)}
        >
          登録
        </PopupSubmit>
      </PopupForm>
    </Popup>
  )
}
