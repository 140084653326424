let withInTrace = false

export const appendTraceToConsoleError = () => {
  if ((console.error as any).appended) return
  const originalConsoleError = console.error
  console.error = (...args: unknown[]) => {
    if (withInTrace) {
      originalConsoleError(...args)
      return
    }
    withInTrace = true
    // eslint-disable-next-line no-console
    console.trace()
    withInTrace = false
    originalConsoleError(...args)
  }
  ;(console.error as any).appended = true
}

/**
 * エラーが握りつぶされるコールバックでエラーが検知できるようにするラッパー
 */
export const withLoggingError =
  <T extends unknown[], U>(f: (...params: T) => U) =>
  (...params: T) => {
    try {
      return f(...params)
    } catch (error) {
      console.error(error)
      throw error
    }
  }
