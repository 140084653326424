import { useEffect, useMemo } from 'react'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { getStackTrace } from '@gijirokukun/shared'
import { FirestoreError } from '../api/loggingFirestore'
import { DocumentData, Query } from 'uni-firebase/firestore'

const snapshotOptions = {
  serverTimestamps: 'estimate',
} as const

export const useCollection = <T extends DocumentData>(
  query: Query<T> | undefined
) => {
  const calledStackTrace = useMemo(() => getStackTrace(), [])
  const [data, loading, error] = useCollectionData<T, 'id', 'ref'>(query, {
    idField: 'id',
    refField: 'ref',
    snapshotOptions,
  })

  useEffect(() => {
    if (error) {
      const useDocumentError = new FirestoreError(
        'useCollection failed',
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        (query as any)?.path ?? 'unknown'
      )
      const head = (useDocumentError.stack ?? '').split('\n')[0]
      useDocumentError.stack = `${head}\n${calledStackTrace ?? ''}`
      if (error.code === 'internal') {
        console.debug(useDocumentError)
        console.error('firestore internal error')
      } else {
        console.error(useDocumentError)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return [data, loading, error] as const
}
