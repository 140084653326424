import React, { useState, useEffect } from 'react'
import { useRouter } from 'src/api/router'
import { UnsupportedPopup } from '../Popup/UnsupportedPopup'
import { isSupportedBrowser } from 'src/constants/support'
import { getFormalizedPathname } from '@gijirokukun/shared'
import { routes } from 'src/constants/routes'
import { App } from 'src/api/firebase'

const BrowserSupport = (props: { children: any }) => {
  const [supported, setSupported] = useState<boolean>(true)
  const router = useRouter()

  useEffect(() => {
    if (
      typeof window === 'undefined' ||
      window.navigator.userAgent.includes('Googlebot')
    ) {
      return
    }
    const openPaths: string[] = [
      routes.landing,
      routes.terms,
      routes.privacy,
      routes.signup,
    ]
    const checkSupported = (uri: string) => {
      const pathname = getFormalizedPathname(
        new URL(uri, 'https://example.com').pathname
      )
      const isOpen = openPaths.includes(pathname)
      if (isOpen || isSupportedBrowser) {
        setSupported(true)
      } else {
        setSupported(false)
        App.analytics.logEvent('service_unsupported_show')
      }
    }
    checkSupported(router.asPath)

    router.events.on('routeChangeComplete', checkSupported)
    return () => {
      router.events.off('routeChangeComplete', checkSupported)
    }
  }, [])

  return (
    <>
      {!supported && <UnsupportedPopup />}
      {props.children}
    </>
  )
}

export { BrowserSupport }
