// Polyfill
import 'core-js'

import 'src/init'

import { appendTraceToConsoleError } from 'src/api/logger'
appendTraceToConsoleError()

// Prevent Breaking Website
import '../api/preventGoogleTranslateBreaking'

// Enable firestore api logging
import 'src/api/loggingFirestore'

import React, { useEffect } from 'react'
import Head from 'next/head'
import { ThemeProvider } from 'styled-components'
import { AuthProvider } from '../contexts/Auth'
import { ToastProvider } from 'react-toast-notifications'
import '@fortawesome/fontawesome-free/css/all.min.css'
import '../assets/scss/top-style.scss'
import '../assets/css/materialdesignicons.min.css'
import '../assets/scss/argon-dashboard-react.scss'
import 'reactjs-popup/dist/index.css'
import { BrowserSupport } from '../components/Organisms/Layouts/BrowserSupport'
import { chakraUItheme, theme } from 'src/components/theme'
import 'react-loading-skeleton/dist/skeleton.css'
import { SkeletonTheme } from 'react-loading-skeleton'
import 'focus-visible/dist/focus-visible'
import { ChakraProvider } from '@chakra-ui/react'
import { useSentry } from 'src/hooks/useSentry'
import { Global, css } from '@emotion/react'
import { AppProps } from 'next/app'
import { useAnalytics } from 'src/hooks/useAnalytics'
import Script from 'next/script'
import { useStripeCallback } from 'src/hooks/useStripe'
import { App as _App } from 'src/api/firebase'

const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible *:focus:not(.focus-visible),
  .js-focus-visible *:focus:not(.focus-visible) + [data-focus] {
    outline: none;
    box-shadow: none;
  }

  /*
    元々Argon dashboardのreset cssを使っていたが、Chakra-UIのreset cssと競合して不安定になっていたので、
    Argon dashboardのreset cssのうち保持したいものだけここに定義している
  */
  label {
    margin-bottom: 0.5rem;
  }
  button {
    padding-block: 1px;
    padding-inline: 6px;
  }
  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: inline;
    vertical-align: middle;
    border-style: none; /* Remove the border on images inside links in IE 10-. */
  }
  a {
    color: #5e72e4;
    text-decoration: none !important;
    outline: none;
  }
  a:hover {
    color: #233dd2;
    text-decoration: none !important;
    outline: none;
  }
`

const ComponentWrapper = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    // Analyticsの設定
    if (process.env.NEXT_PUBLIC_IS_LOCAL !== 'true') {
      _App.initAnalytics()
    }
  }, [])

  useSentry()

  useAnalytics()

  return <Component {...pageProps} />
}

const App = (props: AppProps) => {
  useStripeCallback()
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, maximum-scale=1, width=device-width"
        />
        <title>AI議事録取れる君 - 月額980円(税別)で議事録を自動作成</title>
        <meta
          key="description"
          name="description"
          content="AIが音声を即座に文字起こし、Web会議に使える議事録サービス。AIが自動で要約を作成、リアルタイム共同編集機能により議事録編集の圧倒的な効率化を実現します。翻訳、チーム、録音、ダウンロード機能・業界最安水準月額980円(税別)から。"
        />
        <meta
          key="keywords"
          name="keywords"
          content="AI議事録取れる君, AI議事録, AI要約, 議事録取れる君, 議事録, 文字起こし, 議事録 自動, 要約 自動, 議事録 作成, 議事録 代行, 共同編集, オンライン会議, オンラインミーティング, zoom, teams, ms teams, microsoft teams, meet"
        />
        <meta
          key="ogUrl"
          property="og:url"
          content="https://gijirokukun.com/"
        />
        <meta key="ogType" property="og:type" content="website" />
        <meta key="ogTitle" property="og:title" content="AI議事録取れる君" />
        <meta
          key="ogDescription"
          property="og:description"
          content="AIが音声を即座に文字起こし、Web会議に使える議事録サービス。AIがリアルタイムで要約を作成、共同編集機能により議事録編集の圧倒的な効率化を実現します。ミーティングツール連携、翻訳、チーム、録音、ダウンロード機能・業界最安水準月額980円(税別)から。"
        />
        <meta
          key="ogSiteName"
          property="og:site_name"
          content="AI議事録取れる君 | 月額980円(税別)で議事録を自動作成"
        />
        <meta
          key="ogImage"
          property="og:image"
          content="https://gijirokukun.com/images/ogp/gijirokukun_ogp.png"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="AI議事録取れる君" />
        <meta
          name="twitter:description"
          content="AIが音声を即座に文字起こし、Web会議に使える議事録サービス。AIがリアルタイムで要約を作成、共同編集機能により議事録編集の圧倒的な効率化を実現します。ミーティングツール連携、翻訳、チーム、録音、ダウンロード機能・業界最安水準月額980円(税別)から。"
        />
        <meta
          name="twitter:image"
          content="https://gijirokukun.com/images/ogp/gijirokukun_ogp.png"
        />
      </Head>
      <Script src="/js/lib/web-audio-recorder-js/lib/WebAudioRecorder.js"></Script>
      <Script src="/js/lib/web-audio-recorder-js/lib/Mp3LameEncoder.min.js"></Script>
      <ThemeProvider theme={theme}>
        <ChakraProvider theme={chakraUItheme}>
          <Global styles={GlobalStyles} />
          <SkeletonTheme baseColor="#e6e6e6" highlightColor="#e0e0e0">
            <ToastProvider
              autoDismiss
              autoDismissTimeout={4000}
              placement="bottom-center"
            >
              <AuthProvider>
                <BrowserSupport>
                  <ComponentWrapper {...props} />
                </BrowserSupport>
              </AuthProvider>
            </ToastProvider>
          </SkeletonTheme>
        </ChakraProvider>
      </ThemeProvider>
    </>
  )
}

export default App
