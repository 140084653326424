import React, { SyntheticEvent } from 'react'
import styled from 'styled-components'
import { Copy } from '../../Atoms/Svg'
import Popup from '../../Molecules/Popup/Popup'
import copy from 'copy-to-clipboard'
import { useToasts } from 'react-toast-notifications'
import { domain, routes } from 'src/constants/routes'
import { isMobile } from 'react-device-detect'
import NextLink from 'next/link'
import { Button, Link } from '@chakra-ui/react'
import { useRouter } from 'src/hooks/useRouter'
import { logout } from 'src/models/user'
import { useAuth } from 'src/contexts/Auth'

export const PopupContent = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PopupDescription = styled.div`
  margin: 15px;
  text-align: left;
  color: rgba(0, 0, 0, 0.7);
`

type UnsupportPopupProps = {
  handleClose?: () => void
}

export function UnsupportedPopup(props: UnsupportPopupProps) {
  const { addToast } = useToasts()
  const { router } = useRouter()
  const { resetUser } = useAuth()

  const handleCopy = () => {
    copy(location.href)
    addToast('URLがコピーされました', { appearance: 'success' })
  }

  const exit = () => {
    void logout(resetUser, router, routes.landing)
  }

  return (
    <Popup
      title={
        <span>
          {isMobile ? (
            <>
              パソコンで以下のアドレスを開いて
              <br />
              サービスをご利用下さい
            </>
          ) : (
            'お使いのブラウザはサービスに対応していません'
          )}
        </span>
      }
      layer={10000000}
      clickAway={false}
    >
      <PopupContent>
        <PopupDescription>
          {isMobile && (
            <>
              スマートフォンには対応していません。
              <br />
              <br />
            </>
          )}
          パソコンの対応しているブラウザ（Edge・Safari・Chromeのいずれか）で以下のアドレスを開いてください。
        </PopupDescription>
        <h2
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {domain + ' '}
          <span style={{ position: 'relative', top: '-2px' }}>
            <Copy width={20} height={20} onClick={handleCopy} />
          </span>
        </h2>
        <PopupDescription>
          現在下記の環境に対応しています。
          <br />
          OS： Windows, macOS, Linux
          <br />
          ブラウザ： Google Chrome, Microsoft Edge (最新のバージョン), Safari
          (14.1.1以降)
          <br />
          対応環境からご利用ください。
        </PopupDescription>
        <Button variant="link" textDecor="underline !important" onClick={exit}>
          メインページに戻る
        </Button>
      </PopupContent>
    </Popup>
  )
}
