import { extendTheme } from '@chakra-ui/react'
import { MultiSelectTheme } from 'chakra-multiselect'

export const chakraUItheme = {
  ...extendTheme({
    config: {
      initialColorMode: 'light',
      useSystemColorMode: false,
    },
    colors: {
      primary: '#002850',
      'primary.50': '#002850',
      'primary.100': '#002850',
      'primary.200': '#002850',
      'primary.300': '#002850',
      'primary.400': '#002850',
      'primary.500': '#002850',
      'primary.600': '#002850',
      'primary.700': '#002850',
      'primary.800': '#002850',
      'primary.900': '#002850',
      lightPrimary: '#1D4A77',
      transPrimary: '#00305F50',
      primarySub: '#525f7f',
      primarySub2: '#A5ABBD',
      lightPrimarySub2: '#c2c6d2',
      landingPrimary: '#264297',
      lightLandingPrimary: '#3252b3',
      accentLight: '#00CDF2',
      accentMiddle: '#119DEF',
      accentDark: '#1051FF',
      light: '#fff',
      darkLight: '#E8F2FC',
      darkLight2: '#ECEFF5',
      lightGray: '#E9ECEF',
      lightGray2: '#CCCCCC',
      lightDark: '#696969',
      dark: '#000',
      background: '#F7F7F7',
      lightBorder: '#777',
      darkBorder: '#333',
      iconLight: '#fff',
      textLight: '#fff',
      textLightSub: 'rgba(255,255,255,0.9)',
      warning: '#DE1567',
      warningLight: '#e3367c',
      warningLight2: '#e64b8a',
      surprise: '#F01A8B',
      destructive: '#a32828',
      modalBodyPrimary: '#00305F',
      modalBodyBackground: '#E6E6E6',
    },
    components: {
      Button: {
        baseStyle: {
          border: '0px',
          borderRadius: '20px',
          cursor: 'pointer',
        },
        sizes: {
          sm: {
            h: '26px',
            px: '12px',
            fontSize: '13px',
          },
        },
        variants: {
          solid: {
            minW: 'auto',
            color: 'textLight',
            fontWeight: 'normal',
            bg: 'primary',
            _hover: {
              bg: 'lightPrimary',
              _disabled: {
                bg: 'primary',
              },
            },
            _active: {
              bg: 'lightPrimary',
            },
          },
          destructive: {
            minW: 'auto',
            color: 'textLight',
            fontWeight: 'normal',
            bg: 'destructive',
            _hover: {
              bg: 'destructive',
              _disabled: {
                bg: 'destructive',
              },
            },
            _active: {
              bg: 'destructive',
            },
          },
          warning: {
            minW: 'auto',
            color: 'textLight',
            fontWeight: 'normal',
            bg: 'warning',
            _hover: {
              bg: 'warningLight',
              _disabled: {
                bg: 'warning',
              },
            },
            _active: {
              bg: 'warningLight2',
            },
          },
          cancell: {
            minW: 'auto',
            color: 'textLight',
            fontWeight: 'normal',
            bg: 'primarySub2',
            _hover: {
              bg: 'lightPrimarySub2',
              _disabled: {
                bg: 'primarySub2',
              },
            },
            _active: {
              bg: 'lightPrimarySub2',
            },
          },
          outline: {
            minW: 'auto',
            border: '1px',
            borderColor: 'primary',
            color: 'primary',
            fontWeight: 'normal',
            bg: 'light',
            _hover: {
              bg: 'darkLight',
              _disabled: {
                bg: 'light',
              },
            },
            _active: {
              bg: 'darkLight',
            },
          },
          link: {
            minW: 'auto',
            color: 'primary',
            fontWeight: 'normal',
            bg: 'none',
          },
          pagination: {
            fontWeight: '400',
            w: '20px',
            h: '20px',
            bg: 'lightGray',
            color: 'primarySub',
            borderRadius: '6px',
          },
          pagination_selected: {
            fontWeight: '400',
            w: '20px',
            h: '20px',
            bg: 'primarySub',
            color: 'textLight',
            borderRadius: '6px',
          },
        },
      },
      Divider: {
        baseStyle: {
          borderColor: 'rgba(0, 0, 0, 0.05)',
          my: '15px',
        },
      },
      Tooltip: {
        baseStyle: {
          fontSize: '11px',
          color: 'textLight',
          bg: 'lightDark',
          borderRadius: '4px',
        },
      },
      Text: {
        baseStyle: {
          fontWeight: 'normal',
          fontFamily: 'Noto Sans JP',
          color: 'primary',
          cursor: 'default',
          margin: 0,
        },
        variants: {
          modal: {
            fontWeight: 'bold',
          },
        },
      },
      Heading: {
        baseStyle: {
          cursor: 'default',
          fontFamily: 'Noto Sans JP',
          color: 'primary',
          margin: 0,
        },
        sizes: {
          xs: {
            fontSize: '13px',
            lineHeight: '19px',
          },
        },
      },
      Input: {
        variants: {
          outline: {
            field: {
              borderRadius: '9px',
              _hover: {
                borderColor: 'darkBorder',
              },
            },
          },
          filled: {
            field: {
              bg: 'darkLight2',
              fontSize: '13px',
              color: 'primarySub',
              h: '26px',
              borderRadius: '15px',
            },
          },
        },
      },
      Table: {
        baseStyle: {
          th: {
            color: 'primarySub',
            borderTop: 'solid 1px',
          },
          td: {
            color: 'primarySub',
          },
        },
        sizes: {
          sm: {
            th: {
              px: '8px',
              fontSize: '13px',
            },
            td: {
              px: '8px',
              fontSize: '13px',
            },
          },
        },
      },
      Menu: {
        baseStyle: {
          button: {
            border: 0,
            bg: 'light',
            color: 'primary',
            cursor: 'pointer',
          },
          list: {
            p: 0,
            border: 'solid 0.5px',
            borderColor: 'lightBorder',
            borderRadius: '7px',
            minW: '109px',
          },
          item: {
            border: 0,
            color: 'primarySub',
            fontSize: '13px',
            bg: 'light',
            _focus: {
              color: 'textLight',
              bg: 'primarySub',
            },
            _first: {
              borderTopRadius: '6px',
            },
            _last: {
              borderBottomRadius: '6px',
            },
          },
        },
        variants: {
          roomHeader: {
            button: {
              border: 0,
              bg: 'light',
              color: 'primary',
              cursor: 'pointer',
            },
            list: {
              p: 0,
              border: 'solid 0.5px',
              borderColor: '#959fb7',
              borderRadius: '7px',
              minW: '109px',
            },
            item: {
              border: 0,
              color: 'primarySub',
              fontSize: '13px',
              bg: 'light',
              _focus: {
                bg: 'lightGray',
                color: 'primarySub',
              },
              _first: {
                borderTopRadius: '6px',
              },
              _last: {
                borderBottomRadius: '6px',
              },
            },
          },
        },
      },
      Skeleton: {
        baseStyle: {
          borderRadius: '15px',
        },
      },
      Form: {
        baseStyle: {
          sizes: {
            md: {
              bg: 'red',
              display: 'flex',
              justify: 'space-between',
              align: 'center',
            },
          },
        },
      },
      FormLabel: {
        baseStyle: {
          margin: 0,
          fontWeight: 'bold',
        },
      },
      Checkbox: {
        baseStyle: {
          control: {
            borderRadius: '0.25rem',
            border: 'solid 2px #002850',
            bg: '#fff',
            _checked: {
              bg: '#002850 !important',
              border: 'none !important',
            },
          },
        },
        sizes: {
          md: {
            label: {
              fontSize: '13px',
            },
            control: {
              w: '19px',
              h: '19px',
            },
          },
        },
      },
      Radio: {
        baseStyle: {
          container: {
            margin: '0',
          },
        },
      },
      Switch: {
        baseStyle: {
          container: {
            margin: '0',
          },
        },
        defaultProps: {
          colorScheme: 'primary',
        },
      },
      Popover: {
        baseStyle: {
          popper: {
            zIndex: 9999999,
          },
        },
      },
      Modal: {
        baseStyle: {
          dialog: {
            borderRadius: '11px',
          },
          header: {
            textAlign: 'center',
            bg: 'primary',
            color: 'textLight',
            borderTopRadius: '11px',
            py: '10px',
          },
          body: {
            borderBottomRadius: '11px',
            bg: 'modalBodyBackground',
            color: 'modalBodyPrimary',
            fontWeight: 'bold',
            py: '28px',
          },
          closeButton: {
            color: 'white',
          },
        },
        defaultProps: {
          isCentered: true,
        },
      },
      MultiSelect: {
        ...MultiSelectTheme,
        baseStyle: (props) => ({
          ...MultiSelectTheme.baseStyle(props),
          control: {
            ...MultiSelectTheme.baseStyle(props).control,
            borderRadius: '2px',
            border: 'none',
            boxShadow: '0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%)',
          },
          list: {
            ...MultiSelectTheme.baseStyle(props).list,
            border: '1px solid #c8ccd7',
          },
          item: {
            ...MultiSelectTheme.baseStyle(props).item,
            _hover: {
              bg: '#c8ccd7',
            },
          },
          selectedItem: {
            ...MultiSelectTheme.baseStyle(props).selectedItem,
            bg: 'white',
            border: '1px solid #c8ccd7',
            color: '#002850',
            fontWeight: 'normal',
          },
        }),
      },
    },
  }),
  // globalスタイルはデフォルトを使わずに上書き
  styles: {
    global: {
      body: {
        fontFamily: 'Noto Sans JP',
        color: 'gray.800',
        bg: 'background',
        transitionProperty: 'background-color',
        transitionDuration: 'normal',
        lineHeight: 'base',
      },
      '*::placeholder': {
        color: 'transPrimary',
      },
      '*, *::before, &::after': {
        borderColor: 'lightBorder',
        wordWrap: 'break-word',
      },
      button: {
        cursor: 'pointer',
        border: 'none',
        bg: 'none',
      },
      // Editor
      '.is-editor-empty:not(:hover):before': {
        content: 'attr(data-placeholder)',
        color: '#ccc',
      },
      // Reactour
      '.reactour__close': {
        width: '13px',
        height: '13px',
        top: '10px',
        right: '10px',
      },
      '.reactour__helper': {
        boxShadow: 'rgb(99 99 99 / 20%) 0px 2px 8px 0px !important',
        borderRadius: '10px !important',
        opacity: 0.9,
        backgroundColor: '#ebf8fd !important',
      },
      // Chakra（設定が上手く反映されなかったので取り敢えず直打ちで妥協 後で調べる）
      '.chakra-form-control': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
      },
      '.chakra-form__error-message': {
        fontSize: '12px !important',
      },
    },
  },
}

export default chakraUItheme

/**
 * 旧テーマ
 * @deprecated デザイン作新時に廃止する
 */
export const theme = {
  colors: {
    primary: '#002850',
    primarySub: '#525f7f',
    accentLight: '#00CDF2',
    accentDark: '#1051FF',
    destructive: '#a32828',
    destructiveSub: '#a328284d',
    surprise: '#F01A8B',
    iconLight: '#fff',
    textLight: '#fff',
    textLightSub: 'rgba(255,255,255,0.9)',
  },
} as const

export type Theme = typeof theme
