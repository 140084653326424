import React from 'react'

export const Share = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.217"
    height="19.217"
    viewBox="0 0 19.217 19.217"
  >
    <path
      id="external-link-alt-solid"
      d="M16.214,12.011h-1.2a.6.6,0,0,0-.6.6v4.2H2.4V4.8h5.4a.6.6,0,0,0,.6-.6V3a.6.6,0,0,0-.6-.6H1.8A1.8,1.8,0,0,0,0,4.2V17.415a1.8,1.8,0,0,0,1.8,1.8H15.013a1.8,1.8,0,0,0,1.8-1.8v-4.8A.6.6,0,0,0,16.214,12.011ZM18.316,0h-4.8a.9.9,0,0,0-.638,1.539L14.215,2.88,5.067,12.025a.9.9,0,0,0,0,1.276l.851.849a.9.9,0,0,0,1.276,0L16.337,5l1.341,1.339a.9.9,0,0,0,1.539-.638V.9A.9.9,0,0,0,18.316,0Z"
      fill="#fff"
    />
  </svg>
)
