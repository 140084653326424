import * as Sentry from '@sentry/browser'
import { useEffect } from 'react'
import { useAuth } from 'src/contexts/Auth'

export const useSentry = () => {
  const { auth } = useAuth()
  useEffect(() => {
    if (auth) {
      Sentry.setUser({
        id: auth.uid,
      })
    } else {
      Sentry.setUser(null)
    }
  }, [auth])
}
