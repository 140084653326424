import { useEffect, useMemo, useState } from 'react'
import { RoutePath, routes } from '@gijirokukun/shared'

const domain =
  typeof location !== 'undefined'
    ? `${location.protocol}//${location.host}`
    : 'https://gijirokukun.com'

const useURL = () => {
  const [origin, setOrigin] = useState<string>()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setOrigin(window.location.origin)
    }
  }, [])

  const toURL = useMemo(() => {
    if (origin === undefined) return undefined
    return (route: RoutePath) => {
      return `${origin}${route}`
    }
  }, [origin])

  return {
    origin,
    toURL,
  }
}

export { routes, domain, useURL }
