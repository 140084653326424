import React, { Fragment, useEffect } from 'react'
import { TextProps } from '@chakra-ui/react'
import { CreateStyled } from '@emotion/styled'

// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const sandwich = <T extends unknown>(array: T[], value: T) => {
  const result = []
  for (let i = 0; i < array.length; i++) {
    result.push(array[i])
    if (i !== array.length - 1) {
      result.push(value)
    }
  }
  return result
}

/**
 * テキストの間にwbr（実際には幅なし空白文字）を挟んで、いい感じに改行できるようにする
 * @param text テキスト
 * @param breakBeforeChars その文字の手前にwbrを置きたい文字のリスト
 * @param breakAfterChars その文字の直後にwbrを置きたい文字のリスト
 * @returns wbrが挟まった文字列
 */
export const appendWordBreak = (
  text: string,
  breakBeforeChars: string[],
  breakAfterChars: string[]
) => {
  const breakChars = breakBeforeChars.concat(breakAfterChars)
  const splittedByAnyBreakText = breakChars.reduce(
    (splitted, breakChar) =>
      splitted.flatMap((frag) => sandwich(frag.split(breakChar), breakChar)),
    [text]
  )
  return (
    <>
      {splittedByAnyBreakText.map((frag, i) => {
        if (breakBeforeChars.includes(frag)) {
          return (
            <Fragment key={i}>
              <wbr />
              {frag}
            </Fragment>
          )
        }
        if (breakAfterChars.includes(frag)) {
          return (
            <Fragment key={i}>
              {frag}
              <wbr />
            </Fragment>
          )
        }
        return frag
      })}
    </>
  )
}

export type Clickable = { isDisabled: true } | { onClick: () => void }
export const makeClickable = (
  makeOnClickIfEnabled: () => (() => void | Promise<void>) | undefined
): Clickable => {
  const onClick = makeOnClickIfEnabled()
  if (onClick != null) {
    return { onClick: () => void onClick() }
  } else {
    return { isDisabled: true }
  }
}

export const useClickAway = (
  ref: React.RefObject<HTMLDivElement>,
  callback: () => void,
  excludeRef?: React.RefObject<HTMLElement>
) => {
  useEffect(() => {
    const handleClickAway = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e?.target as Node)) {
        if (
          !excludeRef ||
          (excludeRef && !excludeRef.current?.contains(e.target as Node))
        ) {
          callback()
        }
      }
    }
    document.addEventListener('mousedown', handleClickAway)
    return () => {
      document.removeEventListener('mousedown', handleClickAway)
    }
  }, [ref, callback])
}

/**
 * ↓n行になったら`…`にしてくれる
 * @param n
 */
export const clampText = (n: number): TextProps => {
  return {
    wordBreak: 'break-all',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    css: {
      WebkitLineClamp: n,
      WebkitBoxOrient: 'vertical',
    },
  }
}
