import { useRouter as useNextRouter } from 'src/api/router'

export const useRouter = () => {
  const router = useNextRouter()
  const getQuery = (key: string) => {
    const value = router.query[key]
    if (typeof value === 'string') {
      return value
    }
    return undefined
  }
  return {
    router,
    getQuery,
  }
}
