import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/analytics'
import 'firebase/compat/storage'
import { App } from 'src/api/firebase'
import { Functions, FUNCTION_OPTIONS, User } from '@gijirokukun/shared'
import { UserModel } from '@gijirokukun/shared'
import { OrganizationModel } from '@gijirokukun/shared'
import { routes } from 'src/constants/routes'
import { NextRouter } from 'src/api/router'
import { GoogleAuthCredential, TemplateType } from '@gijirokukun/shared'
import { GoogleSignInContext } from 'src/pages/auth/link/google'
import { PartialDeep } from 'type-fest'
import { sleep, RoutePath, waitFor } from '@gijirokukun/shared'
import { WithFieldValue } from 'uni-firebase/firestore'
import {
  MultiFactorResolver,
  PhoneAuthCredential,
  UserCredential,
} from 'uni-firebase/auth'
import { env } from 'src/config'
import { call } from 'src/api/firebase'
import storage from 'src/api/storage'

/**
 * ログアウト
 * @returns
 */
export async function logout(
  resetAuthContext: () => void,
  router: NextRouter,
  to: RoutePath
) {
  console.debug('Logout')
  await router.push(routes.logout)
  // キャッシュが使用されてエラーが起きてしまう可能性があるので、先に一回ストレージをクリアする
  storage.clear()
  storage.add('lastLogoutAt', Date.now())
  resetAuthContext()
  // ストレージのクリア・authの初期化を待つ
  console.debug('Logout: wait for reset')
  await sleep(500)
  console.debug('Logout: signout')
  await App.auth.signOut()
  storage.clear()
  storage.add('lastLogoutAt', Date.now())
  // ストレージのクリアが非同期で行われる場合があるので、500ms待つ
  await sleep(500)
  await router.push(to)
  return
}

/**
 * Authに関するストレージやコンテキストの状態をリセット
 */
async function resetAuthStatus(resetAuthContext: () => void) {
  storage.clear()
  resetAuthContext()
  await App.auth.signOut()
  storage.clear()
  return
}

/**
 * Googleでサインインをする
 *
 * Googleでサインインする場合は、一旦Googleの認証画面にリダイレクトする必要があるため、signInではなくこれを呼んで、 `routes.linkToGoogle` で受け取る。
 * @param router
 * @param context
 * @param redirectAbsoluteURL
 */
export const signInWithRedirectToGoogle = (
  router: NextRouter,
  context: GoogleSignInContext,
  redirectAbsoluteURL: string,
  requireCalendar: boolean
) => {
  const scope: string[] = []

  // API連携のときにこれらがあると、API連携項目の許可が手動選択になってしまう
  if (context !== 'googleAPILinkage') {
    scope.push(
      'openid',
      'https://www.googleapis.com/auth/userinfo.profile',
      'https://www.googleapis.com/auth/userinfo.email'
    )
  }

  if (requireCalendar) {
    scope.push('https://www.googleapis.com/auth/calendar.readonly')
  }

  App.analytics.logEvent('googleLinkage_started')
  const googleAuthorizeLink = `https://accounts.google.com/o/oauth2/v2/auth?${new URLSearchParams(
    {
      client_id: env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
      redirect_uri: `${location.origin}${routes.linkToGoogle}/`,
      response_type: 'code',
      scope: scope.join(' '),
      access_type: 'offline',
      state: [context, redirectAbsoluteURL, requireCalendar].join(' '),
      include_granted_scopes: 'true',
    }
  ).toString()}`
  void router.push(googleAuthorizeLink)
}

export type SignInResult = {
  isSignUp: boolean
  userCredential: firebase.auth.UserCredential
  user: firebase.User
  uid: string
  email: string
  name: string
}
export type SignInMethod =
  | {
      type: 'email'
      isSignUp: false
      email: string
      password: string
      handleMultiFactorSignIn: (
        resolver: MultiFactorResolver
      ) => Promise<UserCredential>
    }
  | {
      type: 'email'
      isSignUp: true
      name: string
      email: string
      password: string
    }
  | {
      type: 'google'
      credential: GoogleAuthCredential
      requireCalendar: boolean
      handleMultiFactorSignIn: (
        resolver: MultiFactorResolver
      ) => Promise<UserCredential>
    }
/**
 * 議事録くんにサインインする
 *
 * @param context サインアップしようとしているのかログインしようとしているのか。
 * 実際にどちらが行われるかはこの値と関係ない。
 * @param method 手段と入力値。現在メールアドレス認証とGoogle認証に対応している。
 *
 * signInの場合は多要素認証のために`handleMultiFactorSignIn`が必要。`useMultiFactorSignin`を使ってください。
 * @param isCheckedRemember ブラウザを閉じても情報を保持するかどうか。デフォルトは保持。
 * @returns
 *   userCredential ユーザーの認証結果
 *
 *   isSignUp 実際に行われたのがサインアップであるか
 *
 *   user: firebase.User
 *
 *   uid: string
 *
 *   email: string
 *
 *   name: string
 */
export async function signIn(
  context: 'signUp' | 'login',
  method: SignInMethod,
  isCheckedRemember: boolean,
  resetAuthContext: () => void,
  router: NextRouter
): Promise<SignInResult> {
  // ゲストのステータスをリセット
  // 20240105 おそらくこの操作によりログイン直後に一時的にログアウト状態になりエラーが発生する事象があったのでコメントアウトする
  // https://aidealabhq.slack.com/archives/C05BQEECMJP/p1702628426073439
  // await resetAuthStatus(resetAuthContext)

  // 情報を永続化するかどうかを設定
  storage.add('usePersistance', isCheckedRemember)
  if (isCheckedRemember) {
    await App.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  } else {
    await App.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
  }

  // ユーザーがすでにサインアップを済ましているかをチェックする
  const checkIsUserAlreadySignedUp = async (uid: string) => {
    const userDoc = (await UserModel.getDocRef(uid).get()).data()
    return userDoc !== undefined && userDoc.isSignedUp
  }

  // サインイン
  const signInToFirebase = async (): Promise<SignInResult> => {
    const handleMultiFactorSignIn = async (
      error: any,
      handler: (resolver: MultiFactorResolver) => Promise<UserCredential>
    ) => {
      if (error?.code == 'auth/multi-factor-auth-required') {
        const resolver = error.resolver as MultiFactorResolver
        const userCredential = await handler(resolver)
        return userCredential
      } else {
        throw error
      }
    }

    switch (method.type) {
      case 'email': {
        let userCredential: firebase.auth.UserCredential
        if (method.isSignUp) {
          userCredential = await App.auth.createUserWithEmailAndPassword(
            method.email,
            method.password
          )
        } else {
          userCredential = await App.auth
            .signInWithEmailAndPassword(method.email, method.password)
            .catch((error) =>
              handleMultiFactorSignIn(error, method.handleMultiFactorSignIn)
            )
        }

        // すべて存在する
        return {
          isSignUp: method.isSignUp,
          userCredential,
          user: userCredential.user!,
          uid: userCredential.user!.uid,
          email: userCredential.user!.email!,
          name: method.isSignUp
            ? method.name
            : userCredential.user!.displayName!,
        }
      }

      case 'google': {
        // Firebase AuthとGoogle OAuth2をリンク
        const credential = firebase.auth.GoogleAuthProvider.credential(
          null,
          method.credential.access_token
        )
        const userCredential = await App.auth
          .signInWithCredential(credential)
          .catch((error) =>
            handleMultiFactorSignIn(error, method.handleMultiFactorSignIn)
          )

        const uid = userCredential.user!.uid // 存在する

        const isUserAlreadySignedUp = await checkIsUserAlreadySignedUp(uid)

        // すべて存在する
        return {
          isSignUp: !isUserAlreadySignedUp,
          userCredential,
          user: userCredential.user!,
          uid: uid,
          email: userCredential.user!.email!,
          name: (
            userCredential.additionalUserInfo!.profile! as {
              name: string
            }
          ).name,
        }
      }
    }
  }

  const signinToFirebaseResult = await signInToFirebase()

  // Googleサインアップ時には同時にログインも行われるのでどちらも発行する
  if (signinToFirebaseResult.isSignUp) {
    App.analytics.logEvent('sign_up', { method: method.type })
    if (method.type === 'google') {
      App.analytics.logEvent('login', { method: method.type })
    }
  } else {
    App.analytics.logEvent('login', { method: method.type })
  }

  // 設定

  const privateWritableRef = UserModel.getPrivateWritableDocRef(
    signinToFirebaseResult.uid
  )

  // Googleのトークンを更新
  if (method.type === 'google') {
    const scopes = method.credential.scope.split(' ')

    const calendarScopeIncluded = scopes.includes(
      'https://www.googleapis.com/auth/calendar.readonly'
    )

    await waitFor(privateWritableRef, (snapshot) => snapshot.exists)
    await privateWritableRef.update({
      'google_api.access_token': method.credential.access_token,
      'google_api.refresh_token': method.credential.refresh_token,
      'google_api.scopes': scopes,
      'linkageStatus.google_calendar':
        method.requireCalendar === true && calendarScopeIncluded
          ? 'completion'
          : undefined,
      linkageAnySchedulingAPI:
        method.requireCalendar === true && calendarScopeIncluded
          ? true
          : undefined,
    })
  }

  const isEmailVerificationNeededMethod = method.type === 'email'

  // サインアップ時の各種設定
  if (signinToFirebaseResult.isSignUp) {
    // ※functionは時間が掛かるので待たない。カスタマーに関してはどちらにしろ無ければ作られるので問題ない。

    // ユーザーに対応するStripeのカスタマーを作成
    void call('createCustomerIfNotExists')({})

    // サインアップメールを送信（メール認証の場合混乱を防ぐためメアド認証が終わってから送るので今は送らない）
    if (!isEmailVerificationNeededMethod) {
      await privateWritableRef.set(
        {
          isSendSignupEmail: true,
        },
        { merge: true }
      )
      void call('sendSignupEmail')({})
    }

    // はじめての議事録を作成
    void call('createRoom')({
      folder: {
        type: 'user',
      },
      is_private: false,
      name: 'はじめての議事録',
      template: {
        type: 'template',
        id: 'meeting',
      },
      isFirstRoom: true,
    })

    await Promise.all([
      // Authにニックネームを設定
      signinToFirebaseResult.user.updateProfile({
        displayName: signinToFirebaseResult.name,
      }),

      UserModel.getDocRef(signinToFirebaseResult.uid).set(
        {
          // DBにニックネームを設定
          name: signinToFirebaseResult.name,
          // サインアップフラグを立てる
          isSignedUp: true,
        },
        { merge: true }
      ),

      // 認証メールを送信
      method.type === 'email'
        ? signinToFirebaseResult.user.sendEmailVerification()
        : undefined,
    ])
  }
  // ログイン時の各種設定
  else {
    const currentOrganization = await UserModel.getUserBelongedOrganizationRef(
      signinToFirebaseResult.uid
    )
    const privateWritable = await privateWritableRef.get()

    // サインアップメールを送っていないユーザーに送る
    if (
      privateWritable.exists &&
      privateWritable.data()!.isSendSignupEmail !== true
    ) {
      await privateWritableRef.set(
        {
          isSendSignupEmail: true,
        },
        { merge: true }
      )
      void call('sendSignupEmail')({})
    }

    // 組織のユーザーでかつ無効なら弾く
    if (currentOrganization) {
      const authUserAsOrganizationMember =
        await OrganizationModel.getMembersRef(
          OrganizationModel.ref().doc(currentOrganization.id)
        )
          .doc(signinToFirebaseResult.uid)
          .get()
      if (
        authUserAsOrganizationMember.data() === undefined ||
        authUserAsOrganizationMember.data()!.active === false
      ) {
        await logout(resetAuthContext, router, routes.login)
        console.debug(
          'invalid account',
          currentOrganization.id,
          signinToFirebaseResult.uid
        )
        const error = new Error() as any
        error.code = 'app/invalid-organization'
        throw error
      }
    }
  }

  return signinToFirebaseResult
}

export function anonymousLogin(
  callback: (user: firebase.auth.UserCredential) => void
) {
  App.auth.signInAnonymously().then((res) => {
    callback(res)
  })
}

export function resetPassword(email: string) {
  return call('sendPasswordResetLink')({
    mode: 'resetPassword',
    email: email,
  })
}

export async function deleteAccount(user: firebase.User) {
  const userPrivateWritable = (
    await UserModel.getPrivateWritableDocRef(user.uid).get()
  ).data()
  if (userPrivateWritable?.google_api != null) {
    await call('revokeGoogle')({
      refreshToken:
        userPrivateWritable.google_api.refresh_token ??
        userPrivateWritable.google_api.access_token,
    })
  }
  await user.delete()
  storage.clear()
  return
}

export async function revokeRefreshTokens() {
  const res = await call('authRevokeRefreshTokens')({})
  storage.clear()
  return res
}

export function isFirebaseAuthUserAllowChangeEmail(user: firebase.User) {
  // パスワードでログイン出来るならメアドを変更できる
  return (
    user.providerData.length === 1 &&
    user.providerData[0]?.providerId === 'password'
  )
}
