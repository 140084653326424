import { init } from '@gijirokukun/core'
import { isProduction } from './config'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/analytics'
import 'firebase/compat/storage'
import { App } from './api/firebase'

init({
  isProduction,
  firestore: App.db,
  Firestore: firebase.firestore.Firestore,
  Timestamp: firebase.firestore.Timestamp,
  FieldValue: firebase.firestore.FieldValue,
})
