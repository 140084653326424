/* eslint-disable @typescript-eslint/unbound-method */

import { getStackTrace } from '@gijirokukun/shared'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import { isProduction } from 'src/config'

// firestoreの交信をログに流す

export class FirestoreError extends Error {
  static formatPath(path: string) {
    const ps = path.split('/')
    return ps.filter((p, i) => i % 2 === 0).join('/')
  }

  constructor(type: string, path: string) {
    super(`${type}: ${FirestoreError.formatPath(path)}`)
  }
}

const tryGetPath = (obj: any) => {
  // Reference
  if ('path' in obj) {
    return obj.path
  }
  try {
    // Query
    return obj._delegate._query.path.segments.join('/')
  } catch {
    return 'unknown'
  }
}

const _update = firebase.firestore.DocumentReference.prototype.update
firebase.firestore.DocumentReference.prototype.update = function (
  ...args: unknown[]
) {
  const currentStackTrace = getStackTrace()
  if (typeof window !== 'undefined') {
    console.debug('firestore::update', this.path, ...(isProduction ? [] : args))
  }
  return (_update.bind(this) as any)(...args).catch((error: unknown) => {
    console.error(
      new FirestoreError('update document', this.path),
      this.path,
      error,
      ...(isProduction ? [] : args),
      currentStackTrace
    )
    throw error
  })
}

const _set = firebase.firestore.DocumentReference.prototype.set
firebase.firestore.DocumentReference.prototype.set = function (
  ...args: unknown[]
) {
  const currentStackTrace = getStackTrace()
  if (typeof window !== 'undefined') {
    console.debug('firestore::set', this.path, ...(isProduction ? [] : args))
  }
  return (_set.bind(this) as any)(...args).catch((error: unknown) => {
    console.error(
      new FirestoreError('set document', this.path),
      this.path,
      error,
      ...(isProduction ? [] : args),
      currentStackTrace
    )
    throw error
  })
}

const _getDoc = firebase.firestore.DocumentReference.prototype.get
firebase.firestore.DocumentReference.prototype.get = function (
  ...args: unknown[]
) {
  const currentStackTrace = getStackTrace()
  if (typeof window !== 'undefined') {
    console.debug('firestore::get', this.path, ...(isProduction ? [] : args))
  }
  return (_getDoc.bind(this) as any)(...args).catch((error: unknown) => {
    console.error(
      new FirestoreError('get document', this.path),
      this.path,
      error,
      ...(isProduction ? [] : args),
      currentStackTrace
    )
    throw error
  })
}

const _delete = firebase.firestore.DocumentReference.prototype.delete
firebase.firestore.DocumentReference.prototype.delete = function (
  ...args: unknown[]
) {
  const currentStackTrace = getStackTrace()
  if (typeof window !== 'undefined') {
    console.debug('firestore::delete', this.path, ...(isProduction ? [] : args))
  }
  return (_delete.bind(this) as any)(...args).catch((error: unknown) => {
    console.error(
      new FirestoreError('delete document', this.path),
      this.path,
      error,
      ...(isProduction ? [] : args),
      currentStackTrace
    )
    throw error
  })
}

const _add = firebase.firestore.CollectionReference.prototype.add
firebase.firestore.CollectionReference.prototype.add = function (
  ...args: unknown[]
) {
  const currentStackTrace = getStackTrace()
  if (typeof window !== 'undefined') {
    console.debug('firestore::add', this.path, ...(isProduction ? [] : args))
  }
  return (_add.bind(this) as any)(...args).catch((error: unknown) => {
    console.error(
      new FirestoreError('add document', this.path),
      this.path,
      error,
      ...(isProduction ? [] : args),
      currentStackTrace
    )
    throw error
  })
}

const _getCol = firebase.firestore.Query.prototype.get
firebase.firestore.Query.prototype.get = function (...args: unknown[]) {
  const currentStackTrace = getStackTrace()
  if (typeof window !== 'undefined') {
    console.debug(
      'firestore::get',
      tryGetPath(this),
      ...(isProduction ? [] : args)
    )
  }
  return (_getCol.bind(this) as any)(...args).catch((error: unknown) => {
    console.error(
      new FirestoreError('get query', tryGetPath(this)),
      tryGetPath(this),
      error,
      ...(isProduction ? [] : args),
      currentStackTrace
    )
    throw error
  })
}

const _docOnSnapshot = firebase.firestore.DocumentReference.prototype.onSnapshot
firebase.firestore.DocumentReference.prototype.onSnapshot = function (
  ...args: any[]
) {
  try {
    if (typeof window !== 'undefined') {
      console.debug('firestore::onSnapshot(doc)', this.path)
    }

    let options: any
    let next: any
    let error: any
    let complete: any

    if (args.length === 0) {
      //
    } else if (typeof args[0] === 'function') {
      next = args[0]
      error = args[1]
      complete = args[2]
    } else if (args.length === 1) {
      next = args[0].next
      error = args[0].error
      complete = args[0].complete
    } else if (typeof args[1] === 'function') {
      options = args[0]
      next = args[1]
      error = args[2]
      complete = args[3]
    } else {
      options = args[0]
      next = args[1].next
      error = args[1].error
      complete = args[1].complete
    }

    return (_docOnSnapshot.bind(this) as any)(
      options ?? {},
      next ?? (() => {}),
      (e: any) => {
        console.warn(
          new FirestoreError('onSnapshot document', this.path),
          this.path,
          e
        )
        if (error) {
          error(e)
        }
      },
      complete
    )
  } catch (e) {
    console.error(e)
    return (_docOnSnapshot.bind(this) as any)(...args)
  }
}

const _queryOnSnapshot = firebase.firestore.Query.prototype.onSnapshot
firebase.firestore.Query.prototype.onSnapshot = function (...args: any[]) {
  try {
    if (typeof window !== 'undefined') {
      console.debug(
        'firestore::onSnapshot(query)',
        'path' in this ? (this as any).path : 'unknown'
      )
    }

    let options: any
    let next: any
    let error: any
    let complete: any

    if (args.length === 0) {
      //
    } else if (typeof args[0] === 'function') {
      next = args[0]
      error = args[1]
      complete = args[2]
    } else if (args.length === 1) {
      next = args[0].next
      error = args[0].error
      complete = args[0].complete
    } else if (typeof args[1] === 'function') {
      options = args[0]
      next = args[1]
      error = args[2]
      complete = args[3]
    } else {
      options = args[0]
      next = args[1].next
      error = args[1].error
      complete = args[1].complete
    }

    return (_queryOnSnapshot.bind(this) as any)(
      options ?? {},
      next ?? (() => {}),
      (e: any) => {
        console.warn(
          new FirestoreError('onSnapshot query', tryGetPath(this)),
          tryGetPath(this),
          e
        )
        if (error) {
          error(e)
        }
      },
      complete
    )
  } catch (e) {
    console.error(e)
    return (_queryOnSnapshot.bind(this) as any)(...args)
  }
}
