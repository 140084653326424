import { NextRouter, useRouter } from 'src/api/router'
import { useEffect, useState } from 'react'

export const withCallbackURL = (route: string, callbackURL: string) => {
  return {
    pathname: route,
    query: {
      callback: callbackURL,
    },
  }
}

export const useCallbackURL = () => {
  const [callbackURL, setCallbackURL] = useState<string>()
  const router = useRouter()
  useEffect(() => {
    if (typeof router.query.callback === 'string') {
      setCallbackURL(router.query.callback)
    }
  }, [router])

  return callbackURL
}

export const getCallbackURL = (
  router: NextRouter,
  customParamKeyName?: string
) => {
  const value = router.query[customParamKeyName ?? 'callback']

  if (typeof value !== 'string') {
    return undefined
  }

  return value
}
