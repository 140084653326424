/* eslint-disable @typescript-eslint/no-unsafe-assignment */

export const isProduction = process.env.NEXT_PUBLIC_ENV === 'production'

export const env: {
  NEXT_PUBLIC_IS_LOCAL: 'true' | 'false'
  NEXT_PUBLIC_ENV: 'development' | 'production'
  NEXT_PUBLIC_EMULATOR: 'true' | 'false' | 'functions'
  NEXT_PUBLIC_WITH_ZAP?: 'true'
  NEXT_PUBLIC_ZOOM_CLIENT_ID: string
  NEXT_PUBLIC_ZOOM_REDIRECT_HOST: string
  NEXT_PUBLIC_MSTEAMS_CLIENT_ID: string
  NEXT_PUBLIC_MSTEAMS_REDIRECT_HOST: string
  NEXT_PUBLIC_GOOGLE_CLIENT_ID: string
  NEXT_PUBLIC_SENTRY_RELEASE?: string
  NEXT_PUBLIC_TEST?: 'true'
  NODE_ICU_DATA?: string
  SENTRY_RELEASE?: string
  CI?: string
} & {
  TZ?: string
} = {
  NEXT_PUBLIC_IS_LOCAL: process.env.NEXT_PUBLIC_IS_LOCAL as any,
  NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV as any,
  NEXT_PUBLIC_EMULATOR: process.env.NEXT_PUBLIC_EMULATOR as any,
  NEXT_PUBLIC_WITH_ZAP: process.env.NEXT_PUBLIC_WITH_ZAP as any,
  NEXT_PUBLIC_ZOOM_CLIENT_ID: process.env.NEXT_PUBLIC_ZOOM_CLIENT_ID as any,
  NEXT_PUBLIC_ZOOM_REDIRECT_HOST: process.env
    .NEXT_PUBLIC_ZOOM_REDIRECT_HOST as any,
  NEXT_PUBLIC_MSTEAMS_CLIENT_ID: process.env
    .NEXT_PUBLIC_MSTEAMS_CLIENT_ID as any,
  NEXT_PUBLIC_MSTEAMS_REDIRECT_HOST: process.env
    .NEXT_PUBLIC_MSTEAMS_REDIRECT_HOST as any,
  NEXT_PUBLIC_GOOGLE_CLIENT_ID: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as any,
  NEXT_PUBLIC_SENTRY_RELEASE: process.env.NEXT_PUBLIC_SENTRY_RELEASE as any,
  NEXT_PUBLIC_TEST: process.env.NEXT_PUBLIC_TEST as any,
  NODE_ICU_DATA: process.env.NODE_ICU_DATA as any,
  SENTRY_RELEASE: process.env.SENTRY_RELEASE as any,
  CI: process.env.CI as any,
  TZ: process.env.TZ as any,
  ...process.env,
}
