import React from 'react'

export const Trash = (props: {
  onClick?: (e?: React.SyntheticEvent) => void
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={props.onClick}
  >
    <path d="M0,0H14V14H0Z" fill="none" />
    <path
      d="M10.75,4.333h2.917V5.5H12.5v7.583a.583.583,0,0,1-.583.583H3.75a.583.583,0,0,1-.583-.583V5.5H2V4.333H4.917V2.583A.583.583,0,0,1,5.5,2h4.667a.583.583,0,0,1,.583.583ZM11.333,5.5h-7v7h7ZM6.083,7.25H7.25v3.5H6.083Zm2.333,0H9.583v3.5H8.417ZM6.083,3.167V4.333h3.5V3.167Z"
      transform="translate(-0.833 -0.833)"
      fill="rgba(51,51,51,0.4)"
    />
  </svg>
)
