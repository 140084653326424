/* eslint-disable react-hooks/rules-of-hooks */

import { useMemo } from 'react'
import { useDocument } from 'src/hooks/useDocument'
import { Plan } from '@gijirokukun/shared'
import { UserModel } from '@gijirokukun/shared'

class PlanHooks extends Plan {
  public static usePlan(uid: string | undefined) {
    const [userInfoReadonly, loading, error] = useDocument(
      uid != null ? UserModel.getInfoReadonlyDocRef(uid) : undefined
    )
    const [plan, isFallback] = useMemo(() => {
      if (loading) return [undefined, undefined]
      if (userInfoReadonly == undefined) {
        // ゲストで議事録に入ったときなど、userinfoの構築が間に合わない場合はフォールバックする
        return [Plan.fallbackPlan, true]
      }
      return [Plan.getPlanFromDoc(userInfoReadonly), false]
    }, [loading, userInfoReadonly])

    return [plan, { loading, error, isFallback }] as const
  }
}

export const usePlan = PlanHooks.usePlan
