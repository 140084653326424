import { useRouter } from 'src/api/router'
import { useEffect } from 'react'
import { App } from 'src/api/firebase'

export const useAnalytics = () => {
  // デフォルトだとページ遷移時にページ名が記録されるが、議事録の名前が入ってしまうなどして分析に向いていないので、
  // パス名を使うようにする
  const router = useRouter()
  useEffect(() => {
    const pageTitle = router.pathname

    App.analytics.setCurrentScreen(pageTitle)
    App.analytics.logEvent('page_view', {
      page_title: pageTitle,
      page_location: router.pathname,
      page_path:
        router.pathname +
        (typeof window !== 'undefined' ? window.location.search : ''),
    })
    App.analytics.logEvent('screen_view', {
      firebase_screen: pageTitle,
      firebase_screen_class: pageTitle,
    })
  }, [router])
}
