import React from 'react'

export const Microphone = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0H18V18H0Z" fill="none" />
    <path
      d="M9.764 2.5a2.25 2.25 0 0 0-2.25 2.25v3a2.25 2.25 0 1 0 4.5 0v-3A2.25 2.25 0 0 0 9.764 2.5Zm0-1.5a3.75 3.75 0 0 1 3.75 3.75v3a3.75 3.75 0 1 1-7.5 0v-3A3.75 3.75 0 0 1 9.764 1ZM3.055 8.5H4.566a5.252 5.252 0 0 0 10.393 0h1.512a6.753 6.753 0 0 1-5.958 5.959V17.5h-1.5V14.459A6.753 6.753 0 0 1 3.055 8.5Z"
      transform="translate(-0.764 -0.25)"
      fill="#fff"
    />
  </svg>
)
