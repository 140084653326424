import React, { PropsWithChildren } from 'react'
import { Input, InputProps, forwardRef } from '@chakra-ui/react'

export const FormInput = forwardRef<PropsWithChildren<InputProps>, 'input'>(
  (props, ref) => {
    return (
      <Input ref={ref} borderRadius="8px" width="278px" px="10px" {...props}>
        {props.children}
      </Input>
    )
  }
)
