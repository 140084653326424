import React from 'react'
import styled from 'styled-components'

const CopyButton = styled.div`
  &:hover {
    cursor: pointer;
    background-color: #dcdcdc;
  }
  padding: 7px;
`

type SvgProps = {
  width?: number | string
  height?: number | string
  fill?: string
  onClick: (e?: React.SyntheticEvent) => void
}

export const Copy = (props: SvgProps) => (
  <CopyButton>
    <svg
      width={props.width || '16'}
      height={props.height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
    >
      <path d="M0,0H17V17H0Z" fill="none" />
      <path
        d="M5.833,4.833V2.708A.708.708,0,0,1,6.542,2h8.5a.708.708,0,0,1,.708.708v9.917a.708.708,0,0,1-.708.708H12.917v2.125a.711.711,0,0,1-.713.708H3.713A.709.709,0,0,1,3,15.458L3,5.542a.711.711,0,0,1,.713-.708ZM4.419,6.25l0,8.5H11.5V6.25ZM7.25,4.833h5.667v7.083h1.417v-8.5H7.25Z"
        transform="translate(-0.875 -0.583)"
        fill="rgba(51,51,51,0.8)"
      />
    </svg>
  </CopyButton>
)
