import { useEffect, useMemo, useState } from 'react'
import {
  Data,
  StripeModel,
  Subscription,
  SubscriptionInfo,
} from '@gijirokukun/shared'
import { UserModel } from '@gijirokukun/shared'
import { OrganizationMember } from '@gijirokukun/shared'
import { useStorage } from 'src/api/storage'
import { useCollection } from 'src/hooks/useCollection'
import { useDocument, useDocumentOrNull } from 'src/hooks/useDocument'
import { OrganizationModel } from '@gijirokukun/shared'
import { Plan } from '@gijirokukun/shared'
import { usePlan } from 'src/models/plan'
import { useUserBelongedOrganization } from 'src/models/user'
import { Timestamp } from '@gijirokukun/core'
import { StripeModelWeb } from 'src/api/stripe'

export type BotIntegrationStatus = {
  zoom: {
    serviceName: string
    enabled: boolean | undefined
  }
  msTeams: {
    serviceName: string
    enabled: boolean | undefined
  }
  meet: {
    serviceName: string
    enabled: boolean | undefined
  }
}

/**
 * @deprecated Please use auth information through `useAuth`
 */
export const useAuthInformation = (
  user: firebase.default.User | null | undefined
) => {
  // ユーザーのプランは基本的に不変でどのページでも使われるので、画面遷移の際にチカチカしないようにlocalStorageにデータをキャッシュする。
  // firestoreのキャッシュを有効にすればこれはおそらく必要ないが、まだissueなので暫定措置。

  // Variables from storage
  const [serializedPlan, setSerializedPlan] = useStorage('plan')
  const plan = useMemo(
    () => (serializedPlan ? Plan.deserialize(serializedPlan) : undefined),
    [serializedPlan]
  )
  const [isTrial, setIsTrial] = useStorage('isTrial', undefined)
  const [currentMemberOrganization, setCurrentMemberOrganization] = useStorage(
    'currentMemberOrganization'
  )
  const [
    doesUserJoinedSomeTeamAsNonOwner,
    setDoesUserJoinedSomeTeamAsNonOwner,
  ] = useStorage('doesUserJoinedSomeTeamAsNonOwner')
  const [isOrganizationOwner, setIsOrganizationOwner] = useStorage(
    'isOrganizationOwner',
    undefined,
    'session'
  )
  const [canShowPayerInformation, setCanShowPayerInformation] = useStorage(
    'canShowPayerInformation',
    undefined,
    'session'
  )

  // Variables from server
  const [_plan, { isFallback: planIsFallback }] = usePlan(user?.uid)
  const [userInfoReadonly] = useDocument(
    user ? UserModel.getInfoReadonlyDocRef(user.uid) : undefined
  )
  const [userPrivateWritable] = useDocument(
    user ? UserModel.getPrivateWritableDocRef(user.uid) : undefined
  )
  const [userPrivateReadonly] = useDocument(
    user ? UserModel.getPrivateReadonlyDocRef(user.uid) : undefined
  )
  const [userJoinedTeamMembers] = useCollection(
    user ? UserModel.getUserJoinedTeamMemberRefs(user.uid) : undefined
  )
  const [userBelongedOrganization, loadingUserBelongedOrganization] =
    useUserBelongedOrganization(user?.uid)
  const [currentUserAsOrganizationMember] =
    useDocumentOrNull<OrganizationMember>(
      user && currentMemberOrganization
        ? OrganizationModel.getMembersRef(
            OrganizationModel.getOrganizationRef(
              currentMemberOrganization.organizationId
            )
          ).doc(user.uid)
        : undefined
    )
  const [payerAllSubscriptions] = useCollection(
    canShowPayerInformation === true && userInfoReadonly
      ? StripeModelWeb.getSubscriptionsRef(
          StripeModel.getCustomersRef().doc(userInfoReadonly.payer_id)
        )
      : undefined
  )
  const [payerSubscriptionInfo, setPayerSubscriptionInfo] =
    useState<SubscriptionInfo<Data<Subscription>> | null>()
  const [payerUserDoc] = useDocument(
    userInfoReadonly?.payer_id != null
      ? UserModel.getDocRef(userInfoReadonly.payer_id)
      : undefined
  )
  const userBelongedOrganizationRef = userBelongedOrganization?.organizationId
    ? OrganizationModel.ref().doc(userBelongedOrganization.organizationId)
    : undefined
  const [userBelongedOrganizationInfoReadonly] = useDocument(
    userBelongedOrganizationRef
      ? OrganizationModel.getOrganizationInfoReadonlyRef(
          userBelongedOrganizationRef
        )
      : undefined
  )

  // Update storage variables from server's one
  useEffect(() => {
    if (_plan && (plan === undefined || planIsFallback === false)) {
      setSerializedPlan(_plan.serialize())
    }
  }, [_plan, setSerializedPlan])
  useEffect(() => {
    if (canShowPayerInformation === undefined) return

    if (canShowPayerInformation === true) {
      if (!payerAllSubscriptions) return

      const payerSubscription = StripeModel.getActiveSubscriptionFromSnapshot(
        payerAllSubscriptions
      )
      if (payerSubscription != null) {
        setPayerSubscriptionInfo(
          StripeModelWeb.getSubscriptionInfo(payerSubscription)
        )
        setIsTrial(
          payerSubscription.trial_end != null &&
            payerSubscription.trial_end > Timestamp.now()
        )
      } else {
        setPayerSubscriptionInfo(null)
        setIsTrial(false)
      }
    } else {
      setPayerSubscriptionInfo(null)
      setIsTrial(null)
    }
  }, [setIsTrial, canShowPayerInformation, payerAllSubscriptions])
  useEffect(() => {
    if (!loadingUserBelongedOrganization) {
      setCurrentMemberOrganization(userBelongedOrganization ?? null)
    }
  }, [
    userBelongedOrganization,
    loadingUserBelongedOrganization,
    setCurrentMemberOrganization,
  ])
  useEffect(() => {
    if (userJoinedTeamMembers) {
      setDoesUserJoinedSomeTeamAsNonOwner(
        userJoinedTeamMembers.filter((m) => m.role !== 'owner').length > 0
      )
    }
  }, [setDoesUserJoinedSomeTeamAsNonOwner, userJoinedTeamMembers])
  useEffect(() => {
    if (currentUserAsOrganizationMember === undefined) return
    if (currentUserAsOrganizationMember) {
      setIsOrganizationOwner(currentUserAsOrganizationMember.role === 'owner')
    } else {
      setIsOrganizationOwner(false)
    }
  }, [
    currentUserAsOrganizationMember,
    isOrganizationOwner,
    setIsOrganizationOwner,
  ])
  useEffect(() => {
    if (!user || !userInfoReadonly) return
    if (user.uid === userInfoReadonly.payer_id) {
      setCanShowPayerInformation(true)
    } else if (isOrganizationOwner !== undefined) {
      setCanShowPayerInformation(isOrganizationOwner)
    }
  }, [userInfoReadonly, isOrganizationOwner, user, setCanShowPayerInformation])

  const isBotNameChangeOptionEnabled =
    currentMemberOrganization === undefined
      ? undefined
      : currentMemberOrganization
      ? userBelongedOrganizationInfoReadonly
        ? userBelongedOrganizationInfoReadonly.isBotNameChangeOptionEnabled ??
          false
        : undefined
      : userInfoReadonly
      ? userInfoReadonly.isBotNameChangeOptionEnabled ?? false
      : undefined

  const isIPAddressLimitOptionEnabled = userBelongedOrganizationInfoReadonly
    ? userBelongedOrganizationInfoReadonly.isIPAddressLimitOptionEnabled ??
      false
    : undefined

  const isUsageExportOptionEnabled = userBelongedOrganizationInfoReadonly
    ? userBelongedOrganizationInfoReadonly.isUsageExportOptionEnabled ?? false
    : undefined

  const botIntegrationStatus: BotIntegrationStatus = {
    zoom: {
      serviceName: 'Zoom',
      enabled: plan?.feature.isZoomIntegrationEnabled,
    },
    msTeams: {
      serviceName: 'MS Teams',
      enabled: plan?.feature.isMSTeamsMeetingIntegrationEnabled,
    },
    meet: {
      serviceName: 'Google Meet',
      enabled: plan?.feature.isMeetIntegrationEnabled,
    },
  }

  return {
    userInfoReadonly,
    userPrivateWritable,
    userPrivateReadonly,
    isTrial,
    plan,
    canShowPayerInformation,
    payerSubscriptionInfo,
    payerUserDoc,
    currentMemberOrganization,
    doesUserJoinedSomeTeamAsNonOwner,
    isOrganizationOwner,
    isBotNameChangeOptionEnabled,
    isIPAddressLimitOptionEnabled,
    isUsageExportOptionEnabled,
    botIntegrationStatus,
  }
}
