/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable prefer-rest-params */
export {}

// Google翻訳をしても死なないようにするハック by gaearon https://github.com/facebook/react/issues/11538#issuecomment-417504600
// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild
  Node.prototype.removeChild = function <T extends Node>(child: T) {
    if (child.parentNode !== this) {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (console) {
        // eslint-disable-next-line no-console
        console.warn(
          'Cannot remove a child from a different parent',
          child,
          this
        )
      }
      return child
    }
    return originalRemoveChild.apply(this, arguments as any) as T
  }

  const originalInsertBefore = Node.prototype.insertBefore
  Node.prototype.insertBefore = function <T extends Node>(
    newNode: T,
    referenceNode: Node | null
  ) {
    if (referenceNode && referenceNode.parentNode !== this) {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (console) {
        // eslint-disable-next-line no-console
        console.warn(
          'Cannot insert before a reference node from a different parent',
          referenceNode,
          this
        )
      }
      return newNode
    }
    return originalInsertBefore.apply(this, arguments as any) as T
  }
}
