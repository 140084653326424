import React from 'react'

export const Drag = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="10"
    viewBox="0 0 6 10"
  >
    <g id="drag_line" data-name="drag_line" transform="translate(-881 -239)">
      <g
        id="circle_16"
        data-name="circle 16"
        transform="translate(881 239)"
        fill="#fff"
        stroke="#2845a3"
        strokeWidth="1"
      >
        <circle cx="1" cy="1" r="1" stroke="none" />
        <circle cx="1" cy="1" r="0.5" fill="none" />
      </g>
      <g
        id="circle_17"
        data-name="circle 17"
        transform="translate(885 239)"
        fill="#fff"
        stroke="#2845a3"
        strokeWidth="1"
      >
        <circle cx="1" cy="1" r="1" stroke="none" />
        <circle cx="1" cy="1" r="0.5" fill="none" />
      </g>
      <g
        id="circle_18"
        data-name="circle 18"
        transform="translate(881 243)"
        fill="#fff"
        stroke="#2845a3"
        strokeWidth="1"
      >
        <circle cx="1" cy="1" r="1" stroke="none" />
        <circle cx="1" cy="1" r="0.5" fill="none" />
      </g>
      <g
        id="circle_19"
        data-name="circle 19"
        transform="translate(885 243)"
        fill="#fff"
        stroke="#2845a3"
        strokeWidth="1"
      >
        <circle cx="1" cy="1" r="1" stroke="none" />
        <circle cx="1" cy="1" r="0.5" fill="none" />
      </g>
      <g
        id="circle_20"
        data-name="circle 20"
        transform="translate(881 247)"
        fill="#fff"
        stroke="#2845a3"
        strokeWidth="1"
      >
        <circle cx="1" cy="1" r="1" stroke="none" />
        <circle cx="1" cy="1" r="0.5" fill="none" />
      </g>
      <g
        id="circle_21"
        data-name="circle 21"
        transform="translate(885 247)"
        fill="#fff"
        stroke="#2845a3"
        strokeWidth="1"
      >
        <circle cx="1" cy="1" r="1" stroke="none" />
        <circle cx="1" cy="1" r="0.5" fill="none" />
      </g>
    </g>
  </svg>
)
