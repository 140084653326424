import React from 'react'

export const Plus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8.708"
    height="8.708"
    viewBox="0 0 8.708 8.708"
  >
    <path
      id="plus_line"
      data-name="plus_line"
      d="M8.732,8.732V5H9.976V8.732h3.732V9.976H9.976v3.732H8.732V9.976H5V8.732Z"
      transform="translate(-5 -5)"
      fill="#2845a3"
    />
  </svg>
)
