import { useRouter } from 'src/api/router'
import { useEffect, useState } from 'react'
import { useStorage } from 'src/api/storage'
import { App } from 'src/api/firebase'

// FIXME: メインのPrice.tsxのクライアントのStripe周りの処理をここにまとめたい

/**
 * ストライプで購入したときのコールバックをキャッチしてAnalyticsを送信する
 * @returns
 */
export const useStripeCallback = () => {
  // 購入時にStorageに保存された購入情報を用いる
  const [pendingBillingTransactionId, _setPendingBillingTransactionId] =
    useStorage('pendingBillingTransactionId')
  const [pendingCart, _setPendingCart] = useStorage('pendingCart')

  const router = useRouter()

  // クエリからコールバックの結果を受け取る
  const [callbackCheckoutResult, setCallbackCheckoutResult] = useState<
    'success' | 'canceled' | null
  >()
  useEffect(() => {
    if (
      typeof router.query.success === 'string' &&
      router.query.success === 'true'
    ) {
      setCallbackCheckoutResult('success')
    } else if (
      typeof router.query.canceled === 'string' &&
      router.query.canceled === 'true'
    ) {
      setCallbackCheckoutResult('canceled')
    } else {
      setCallbackCheckoutResult(null)
    }
  }, [router.query.success, router.query.canceled])

  // コールバックの結果に応じてアナリティクスを送信する
  useEffect(() => {
    if (
      callbackCheckoutResult != null &&
      pendingBillingTransactionId != null &&
      pendingCart != null
    ) {
      switch (callbackCheckoutResult) {
        case 'success': {
          App.analytics.logEvent('purchase', {
            transaction_id: pendingBillingTransactionId,
            ...pendingCart,
          })
          console.debug('purchase success!')
          break
        }

        case 'canceled': {
          App.analytics.logEvent('remove_from_cart', pendingCart)
          console.debug('purchase cancelled')
          break
        }
      }
    }
  }, [callbackCheckoutResult, pendingBillingTransactionId, pendingCart])

  return {
    callbackCheckoutResult,
    pendingBillingTransactionId,
    pendingCart,
  }
}
