import { useEffect, useMemo } from 'react'
import { useStorage } from 'src/api/storage'
import { App } from 'src/api/firebase'
import { Attendee, Room, Member, Team } from '@gijirokukun/shared'
import { useJoinedCollection } from 'src/hooks/useJoinedCollection'
import { UserModel } from '@gijirokukun/shared'
import { useDocument } from 'src/hooks/useDocument'
import { CollectionReference, DocumentReference } from 'uni-firebase/firestore'

export * from './authInformation'
export * from './auth'

export const useUserBelongedOrganization = (userId: string | undefined) => {
  const [cachedOrg, setCachedOrg] = useStorage('userBelongedOrganization')

  const [infoReadonly, loadingInfoReadonly] = useDocument(
    userId ? UserModel.getInfoReadonlyDocRef(userId) : undefined
  )

  const [org, loadingOrg] = useDocument(infoReadonly?.belonged_organization_ref)

  const serializedOrgs = useMemo(
    () => (org ? { name: org.name, organizationId: org.id } : undefined),
    [org]
  )

  const loading = userId === undefined || loadingInfoReadonly || loadingOrg

  useEffect(() => {
    if (!loading) {
      setCachedOrg(serializedOrgs ?? null)
    }
  }, [serializedOrgs, setCachedOrg, loading])

  return [loading ? cachedOrg ?? undefined : serializedOrgs, loading] as const
}

/**
 * 最近訪れた議事録一覧を返す
 *
 * last_visited_atが無い古い議事録は表示されない
 *
 * @param userId
 * @param limit
 * @returns
 */
export const useRecentlyVisitedRooms = (
  userId: string | undefined,
  limit: number
) => {
  const query = useMemo(
    () =>
      userId
        ? (App.db.collectionGroup('attendees') as CollectionReference<Attendee>)
            .where('ref', '==', UserModel.getDocRef(userId))
            .orderBy('last_visited_at', 'desc')
            .limit(limit)
        : undefined,
    [userId, limit]
  )
  return useJoinedCollection<Attendee, Room>(
    query,
    (doc) => doc.ref.parent.parent as DocumentReference<Room>
  )
}

export const useJoinedTeams = (userId: string | undefined) => {
  const query = useMemo(
    () => (userId ? UserModel.getUserJoinedTeamMemberRefs(userId) : undefined),
    [userId]
  )

  return useJoinedCollection<Member, Team>(
    query,
    (doc) => doc.ref.parent.parent as DocumentReference<Team>
  )
}
