import React from 'react'

export function Eye(props: {
  onClick: (e?: React.SyntheticEvent) => void
  isHidden: boolean
}) {
  if (props.isHidden) {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={props.onClick}
      >
        <path d="M0,0H17V17H0Z" fill="none" />
        <path
          d="M8.844,3a7.8,7.8,0,0,1,7.663,6.375,7.794,7.794,0,0,1-15.327,0A7.794,7.794,0,0,1,8.844,3Zm0,11.333a6.378,6.378,0,0,0,6.217-4.957,6.379,6.379,0,0,0-12.434,0A6.379,6.379,0,0,0,8.844,14.333Zm0-1.771a3.188,3.188,0,1,1,3.188-3.187A3.188,3.188,0,0,1,8.844,12.563Zm0-1.417A1.771,1.771,0,1,0,7.074,9.375,1.771,1.771,0,0,0,8.844,11.146Z"
          transform="translate(-0.344 -0.875)"
          fill="#333"
        />
      </svg>
    )
  }
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
    >
      <path d="M0,0H17V17H0Z" fill="none" />
      <path id="Path_49" data-name="Path 49" d="M0,0H17V17H0Z" fill="none" />
      <path
        d="M1.511,8.5s1.322,5.695,6.965,5.695S15.506,8.5,15.506,8.5"
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeWidth="1.5"
      />
      <path
        d="M0,0V2"
        transform="translate(8.5 14.5)"
        fill="none"
        stroke="#333"
        strokeWidth="1"
      />
      <path
        d="M1,0,0,1"
        transform="translate(1.5 11.5)"
        fill="none"
        stroke="#333"
        strokeWidth="1"
      />
      <path
        d="M0,0,1,1"
        transform="translate(14.5 11.5)"
        fill="none"
        stroke="#333"
        strokeWidth="1"
      />
      <path
        d="M1,0,0,2"
        transform="translate(4.5 13.5)"
        fill="none"
        stroke="#333"
        strokeWidth="1"
      />
      <path
        d="M0,0,1,2"
        transform="translate(11.5 13.5)"
        fill="none"
        stroke="#333"
        strokeWidth="1"
      />
    </svg>
  )
}
