import React from 'react'
import styled from 'styled-components'

const RotatingSVG = styled.svg`
  @keyframes rotation1 {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  animation: 3s linear infinite rotation1;
`

export const LoadingCircle = () => (
  <RotatingSVG
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="1"
        y1="0.351"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#2845a3" />
        <stop offset="0.537" stopColor="#eaeaea" />
        <stop offset="1" stopColor="#fff" />
      </linearGradient>
    </defs>
    <g id="Group_123" data-name="Group 123" transform="translate(-906 -594)">
      <circle
        cx="18"
        cy="18"
        r="18"
        transform="translate(906 594)"
        opacity="0.7"
        fill="url(#linear-gradient)"
      />
      <circle
        cx="16"
        cy="16"
        r="16"
        transform="translate(908 596)"
        fill="#fff"
      />
    </g>
  </RotatingSVG>
)
