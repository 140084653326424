import { useCallback, useEffect, useState } from 'react'
import { useDeepCompareMemoize } from 'use-deep-compare-effect'
import { randomID } from '@gijirokukun/shared'
import { StorageSchema } from 'src/constants/storageSchema'

const KEY_PREFIX = 'gijirokukun'
const buildStorageKey = (key: string): string => `${KEY_PREFIX}_${key}`

let globalStorage: globalThis.Storage

function replacer(key: any, value: any) {
  if (value === Infinity) return '__Infinity__'
  else if (Number.isNaN(value)) return '__Nan__'
  else return value
}

function reviver(key: any, value: any) {
  if (value === '__Infinity__') return Infinity
  else if (value === '__NaN__') return NaN
  else return value
}

export const storage = {
  /**
   * ストレージに値を追加する
   */
  add<K extends keyof StorageSchema>(
    key: K,
    value: StorageSchema[K],
    forceUsedStorageType?: 'session' | 'local'
  ): void {
    if (!process.browser) return
    if (key === 'usePersistance') {
      if (value === true) {
        globalStorage = localStorage
      } else {
        globalStorage = sessionStorage
      }
    }
    const storageKey: string = buildStorageKey(key)
    const storage = (() => {
      switch (forceUsedStorageType) {
        case 'local':
          return localStorage
        case 'session':
          return sessionStorage
        default:
          return globalStorage
      }
    })()
    storage.setItem(storageKey, JSON.stringify(value, replacer))
  },
  /**
   * ストレージから値を取得する
   *
   * @template K
   * @param {K} key
   * @return {*}  {(Storage[K] | null | undefined)} SSRではundefined、値が無ければnullが返る
   */
  get<K extends keyof StorageSchema>(
    key: K,
    forceUsedStorageType?: 'session' | 'local'
  ): StorageSchema[K] | null | undefined {
    if (!process.browser) return undefined
    const storageKey: string = buildStorageKey(key)
    const storage = (() => {
      switch (forceUsedStorageType) {
        case 'local':
          return localStorage
        case 'session':
          return sessionStorage
        default:
          return globalStorage
      }
    })()
    const data = storage.getItem(storageKey)
    if (data != null) {
      return JSON.parse(data, reviver) as StorageSchema[K]
    } else {
      return null
    }
  },
  /**
   * ストレージから値を削除する
   */
  remove<K extends keyof StorageSchema>(key: K): void {
    if (!process.browser) return
    const storageKey: string = buildStorageKey(key)
    globalStorage.removeItem(storageKey)
  },
  /**
   * ストレージから KEY_PREFIX から始まる値を全て削除する
   */
  clear(): void {
    if (!process.browser) return
    const clearSpecificStorage = (storage: globalThis.Storage) => {
      const storageAllKey = []
      for (let i = 0; i < storage.length; i++) {
        storageAllKey.push(storage.key(i))
      }
      storageAllKey.forEach((storageKey) => {
        if (storageKey == null || !storageKey.startsWith(KEY_PREFIX)) {
          return
        }
        storage.removeItem(storageKey)
      })
    }
    clearSpecificStorage(localStorage)
    clearSpecificStorage(sessionStorage)
    Object.values(useStorageClearRegister).forEach((setValue) => {
      setValue(undefined)
    })
    useStorageClearRegister = {}
  },
}

// ストレージのデフォルトはsessionStorageだが、
// localStorageにusePersistanceフラグが立っていればlocalStorageにする
if (process.browser) {
  globalStorage = localStorage
  const usePersistance = storage.get('usePersistance')
  globalStorage = usePersistance === true ? localStorage : sessionStorage
}

export default storage

/**
 * clear()時にuseStorageのvalueをすべてundefinedにする
 */
let useStorageClearRegister: Record<string, (value: undefined) => void> = {}

export const useStorage = <K extends keyof StorageSchema>(
  key: K,
  _initialStateIfValueNotExist?: StorageSchema[K],
  forceUsedStorageType?: 'session' | 'local'
): [StorageSchema[K] | undefined, (value: StorageSchema[K]) => void] => {
  const initialStateIfValueNotExist = useDeepCompareMemoize(
    _initialStateIfValueNotExist
  )

  const [value, _setValue] = useState(() => {
    const value = storage.get(key, forceUsedStorageType)
    if (value === undefined) {
      return undefined
    }
    return value ?? initialStateIfValueNotExist
  })

  useEffect(() => {
    const id = randomID()
    useStorageClearRegister[id] = _setValue
    return () => {
      delete useStorageClearRegister[id]
    }
  }, [])

  useEffect(() => {
    _setValue(
      storage.get(key, forceUsedStorageType) ?? initialStateIfValueNotExist
    )
  }, [forceUsedStorageType, initialStateIfValueNotExist, key])

  const setValue = useCallback(
    (value: StorageSchema[K]) => {
      _setValue(value)
      storage.add(key, value, forceUsedStorageType)
    },
    [forceUsedStorageType, key]
  )

  // 初期レンダリング時はSSGとレンダリングをあわせる必要があるので、valueはundefinedを返す
  const [isFirstRendering, setIsFirstRendering] = useState(true)
  useEffect(() => {
    setIsFirstRendering(false)
  }, [])

  return [isFirstRendering ? undefined : value, setValue]
}
