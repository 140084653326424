import firebase from 'firebase/compat/app'
import 'firebase/compat/app-check'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/analytics'
import 'firebase/compat/storage'
import {
  FirebaseConfig,
  firebaseConfigDevelopment,
  firebaseConfigProduction,
} from '@gijirokukun/core'
import { env } from 'src/config'
import { LogEventFuncType } from './analytics'
import { FUNCTION_OPTIONS, Functions } from '@gijirokukun/shared'
// eslint-disable-next-line no-restricted-imports
import {
  FunctionsErrorCode as V9_FunctionsErrorCode,
  FunctionsError as V9_FunctionsError,
} from 'firebase/functions'
// eslint-disable-next-line no-restricted-imports
import { FirebaseError as V9_FirebaseError } from 'firebase/app'

class Firebase {
  private static instance: Firebase
  auth: firebase.auth.Auth
  db: firebase.firestore.Firestore
  functions: firebase.functions.Functions
  storage: firebase.storage.Storage
  config: FirebaseConfig

  private constructor() {
    // Initialize app
    if (
      typeof location !== 'undefined' &&
      location.hostname.startsWith('minutes-maker-dev')
    ) {
      if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfigDevelopment)
      } else {
        console.warn('Maybe no problem but app construct twice')
      }
      this.config = firebaseConfigDevelopment
    } else {
      switch (env.NEXT_PUBLIC_ENV) {
        case 'development':
          if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfigDevelopment)
          } else {
            console.warn('Maybe no problem but app construct twice')
          }
          this.config = firebaseConfigDevelopment
          break
        case 'production':
          if (!firebase.apps.length) {
            firebase.initializeApp(firebaseConfigProduction)
          } else {
            console.warn('Maybe no problem but app construct twice')
          }
          this.config = firebaseConfigProduction
          break
        default:
          throw new Error('Wrong NEXT_PUBLIC_ENV')
      }
    }

    // Make instance
    this.auth = firebase.auth()
    this.db = firebase.firestore()
    this.functions = firebase
      .app()
      .functions(
        env.NEXT_PUBLIC_ENV === 'production'
          ? 'https://functions-lb.gijirokukun.com'
          : undefined
      )
    this.storage = firebase.storage()

    // Setup instance
    this.db.settings({
      ignoreUndefinedProperties: true,
      merge: true,
    })

    if (env.NEXT_PUBLIC_WITH_ZAP === 'true') {
      // https://stackoverflow.com/questions/71359185/owasap-zap-vulnerability-diagnosis-cannot-be-performed-with-services-using-fireb
      this.db.settings({
        experimentalForceLongPolling: true,
        experimentalAutoDetectLongPolling: false,
        merge: true,
      })
    }

    // Use emulator in instance
    if (
      env.NEXT_PUBLIC_TEST === 'true' ||
      (typeof location !== 'undefined' && location.hostname === 'localhost')
    ) {
      if (env.NEXT_PUBLIC_EMULATOR === 'true') {
        this.db.useEmulator('localhost', 9000)
        this.storage.useEmulator('localhost', 9004)
        this.auth.useEmulator('http://localhost:9003')
        this.functions.useEmulator('localhost', 9001)
      } else if (env.NEXT_PUBLIC_EMULATOR === 'functions') {
        this.functions.useEmulator('localhost', 9001)
      }
    }
  }

  static getInstance() {
    if (!Firebase.instance) {
      Firebase.instance = new Firebase()
    }

    return Firebase.instance
  }

  initAnalytics() {
    this.analytics
  }

  get analytics() {
    if (env.NEXT_PUBLIC_IS_LOCAL === 'true') {
      const dummy: {
        logEvent: LogEventFuncType
      } & Omit<firebase.analytics.Analytics, 'logEvent'> = {
        app: firebase.app(),
        logEvent: () => {},
        setCurrentScreen: () => {},
        setUserId: () => {},
        setUserProperties: () => {},
        setAnalyticsCollectionEnabled: () => {},
      }
      return dummy
    }
    const fbAnalytics = firebase.analytics()
    const analytics: firebase.analytics.Analytics = {
      logEvent: (eventName: string, eventParams: unknown) => {
        if (eventName.length > 40) {
          console.error(`analytics event name '${eventName}' length is over 40`)
        }

        // イベント集約イベントを投げる（無限ループしないように注意）
        const anyDigests: readonly any[] = [
          'gijiroku_action_section_create_on_digest',
          'gijiroku_action_section_insert_to_dial',
          'gijiroku_action_dialogue_edit_to_digest',
          'gijiroku_action_dialogue_edit_to_ndigest',
          'gijiroku_action_memo_add',
          'gijiroku_action_presection_add',
          'gijiroku_action_noteline_edit_blur',
          'gijiroku_action_noteline_delete',
          'gijiroku_action_jump',
        ] as const
        if (anyDigests.includes(eventName as any)) {
          this.analytics.logEvent('gijiroku_action_any_digest')
        }

        // Google Analyticsのイベントを投げる
        fbAnalytics.logEvent(eventName, eventParams as any)
      },
      app: fbAnalytics.app,
      setCurrentScreen: fbAnalytics.setCurrentScreen.bind(fbAnalytics),
      setUserId: fbAnalytics.setUserId.bind(fbAnalytics),
      setUserProperties: fbAnalytics.setUserProperties.bind(fbAnalytics),
      setAnalyticsCollectionEnabled:
        fbAnalytics.setAnalyticsCollectionEnabled.bind(fbAnalytics),
    }
    return analytics as {
      logEvent: LogEventFuncType
    } & Omit<firebase.analytics.Analytics, 'logEvent'>
  }
}

export const App = Firebase.getInstance()

export function call<T extends keyof Functions>(
  funcName: T,
  options?: firebase.functions.HttpsCallableOptions
) {
  return async (
    params: Functions[typeof funcName]['params']
  ): Promise<{ data: Functions[typeof funcName]['returns'] }> => {
    if (
      options?.timeout == null &&
      FUNCTION_OPTIONS[funcName]?.timeoutSeconds != null
    ) {
      options = options ?? {}
      options.timeout = FUNCTION_OPTIONS[funcName]!.timeoutSeconds! * 1000 + 10 // デフォルトのタイムアウトはfunctions 60s, web 70s
    }

    // JSONで表現出来ない型がfirebase-js-sdkでは適切にフォーマットしてくれないので一回stringifyを通して整形する
    const data = JSON.parse(JSON.stringify(params))

    const tryCall = (): ReturnType<
      ReturnType<typeof App.functions.httpsCallable>
    > => {
      return App.functions
        .httpsCallable(
          funcName,
          options
        )(data)
        .catch(async (error) => {
          console.debug(error)
          if (error?.code === 'deadline-exceeded') {
            throw new Error(`deadline exceeded in ${funcName}`, {
              cause: error,
            })
          } else {
            throw error
          }
        })
    }

    return tryCall()
  }
}

export const getCode = (error: any) => {
  if (error instanceof Error && 'code' in error) {
    return (error as any).code
  }
  return undefined
}

// compatのエラーは更新されていない
export type FunctionsErrorCode = V9_FunctionsErrorCode
export type FunctionsError = V9_FunctionsError
export const FirebaseError = V9_FirebaseError
