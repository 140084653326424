import React, { CSSProperties, useRef } from 'react'
import { useClickAway } from 'src/api/dom'
import styled, { css, keyframes } from 'styled-components'
import CloseButton from './CloseButton'

const fadeUp = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0px); }
`
const PopupCard = styled.div<{ $layer: number }>`
  /* width: 336px; */
  @media screen and (min-width: 790px) {
    min-width: 336px;
  }
  background: #fff;
  border-radius: 10px;

  z-index: ${(props) => props.$layer};

  animation: ${fadeUp} 0.125s ease-in-out 0s 1 normal;
`
const PopupHeader = styled.div`
  min-height: 72px;
  width: 100%;

  /* Gray +2 */
  background: #f8f8fa;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  display: flex;
  flex-direction: row;
`
const PopupTitle = styled.h2<{ $isRightMagin: boolean }>`
  margin: 16px 16px 16px ${(props) => (props.$isRightMagin ? '44px' : '16px')};

  flex-grow: 1;
  align-self: center;

  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
`

export const PopupForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const PopupFormContent = styled.div`
  min-height: 102px;
  padding: 16px 16px 6px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  white-space: pre-line;
  text-align: center;
`

export const PopupSubmit = styled.button`
  border: none;

  background: linear-gradient(90deg, #00b8ff 0%, #0080ff 100%);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);

  width: 100%;
  height: 64px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  /* Text */
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  text-align: center;

  color: #ffffff;
  padding: 20px;

  cursor: pointer;
`

export const PopupText = styled.div`
  padding: 20px;
`

const popupButtonBase = css`
  border: none;
  cursor: pointer;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  width: 50%;
  height: 64px;
  /* Text */
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
  padding: 20px;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

export const PopupWarningButton = styled.button`
  ${popupButtonBase}
  background: linear-gradient(90deg, #ff008c 0%, #ff0055 100%);
  border-bottom-right-radius: 10px;
`

export const PopupPositiveButton = styled.button`
  ${popupButtonBase}
  background: linear-gradient(90deg, #00b8ff 0%, #0080ff 100%);
  border-bottom-right-radius: 10px;
`

export const PopupNegativeButton = styled.button`
  ${popupButtonBase}
  background: linear-gradient(90deg, #cccccc 0%, #bbbbbb 100%);
  border-bottom-left-radius: 10px;
`

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`
export const Overlay = styled.div<{ $layer: number }>`
  height: 100%;
  width: 100%;

  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  align-items: center;
  justify-content: center;

  animation: ${fadeIn} 0.3s ease 0s 1 normal;

  z-index: ${(props) => props.$layer};
`

type PopupProps = {
  id?: string
  title: string | JSX.Element
  children: any
  handleClose?: () => void
  layer?: number
  clickAway?: boolean
  withButton?: boolean
  style?: CSSProperties
}

/**
 * @deprecated use chakra-ui Modal component
 */
export default function Popup(props: PopupProps) {
  const { title, children, handleClose, layer, clickAway, withButton, style } =
    props
  const popupRef = useRef(null)
  if (clickAway !== false) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useClickAway(popupRef, () => {
      if (handleClose) {
        handleClose()
      }
    })
  }
  return (
    <Overlay $layer={layer ? layer + 100 : 1100}>
      <PopupCard
        id={props.id}
        ref={popupRef}
        $layer={layer ? layer + 100 : 10100}
        style={withButton ? { width: '336px', ...style } : { ...style }}
      >
        <PopupHeader>
          <PopupTitle $isRightMagin={handleClose ? true : false}>
            {title}
          </PopupTitle>
          {handleClose && <CloseButton onClick={handleClose} />}
        </PopupHeader>
        {children}
      </PopupCard>
    </Overlay>
  )
}
