import { env } from 'src/config'
import {
  Customer,
  Price,
  Product,
  Subscription,
  querySnapshotToData,
  StripeModel,
  Data,
} from '@gijirokukun/shared'
import { CollectionReference } from 'uni-firebase/firestore'
import { DocumentReference } from 'uni-firebase/firestore'
import { firestore } from '@gijirokukun/core'

export const STRIPE_PUBLIC_KEY =
  env.NEXT_PUBLIC_ENV === 'production'
    ? 'pk_live_51J0jL9BDo8MB9BQw3zR1Apjek2VekufWFj3h4kb6yRDMwpcOQKp7odYNewVsTqsHafCyEczUp0XrnQSfR5BBOJRu00mVIFFHxp'
    : 'pk_test_51J0jL9BDo8MB9BQwxEReNpm67v1V8rmx69RAgKaV54vRUz1wGqplx50k9kQxUr7E066peOZ4nO8SctYt3cIcPEj800J9CGbAJ1'

/**
 * Stripeの情報はStripeとFirestoreの２箇所にあり、AdminからはStripe、WebからはFirestoreにアクセスするようにしている
 */
export namespace StripeModelWeb {
  export function getProductsRef() {
    return firestore.collection('products') as CollectionReference<Product>
  }

  export function getSubscriptionsRef(
    customersRef: DocumentReference<Customer>
  ) {
    return customersRef.collection(
      'subscriptions'
    ) as CollectionReference<Subscription>
  }

  export function getPricesRef(productRef: DocumentReference<Product>) {
    return productRef.collection('prices') as CollectionReference<Price>
  }

  export async function getActiveSubscription(uid: string) {
    // https://stripe.com/docs/api/subscriptions/object
    const docs = await getSubscriptionsRef(
      StripeModel.getCustomersRef().doc(uid)
    ).get()
    const subs = docs.docs.map((snapshot) => querySnapshotToData(snapshot))
    return StripeModel.getActiveSubscriptionFromSnapshot(subs)
  }

  export async function getActiveSubscriptionInfo(uid: string) {
    const sub = await StripeModelWeb.getActiveSubscription(uid)
    if (sub == null) return null
    return StripeModel.getSubscriptionInfo(uid, sub, sub.items)
  }

  export function getSubscriptionInfo(sub: Data<Subscription>) {
    return StripeModel.getSubscriptionInfo(
      sub.ref.parent.parent!.id,
      sub,
      sub.items
    )
  }
}
