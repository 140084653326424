import { useEffect, useMemo } from 'react'
import {
  useDocumentData as useRFHDocumentData,
  useDocument as useRFHDocument,
} from 'react-firebase-hooks/firestore'
import { getStackTrace } from '@gijirokukun/shared'
import { FirestoreError } from '../api/loggingFirestore'
import { DocumentData, DocumentReference } from 'uni-firebase/firestore'

const snapshotOptions = {
  serverTimestamps: 'estimate',
} as const

export const useDocument = <T extends DocumentData>(
  docRef: DocumentReference<T> | undefined
) => {
  const calledStackTrace = useMemo(() => getStackTrace(), [])
  const [data, loading, error] = useRFHDocumentData<T, 'id', 'ref'>(docRef, {
    idField: 'id',
    refField: 'ref',
    snapshotOptions,
  })

  useEffect(() => {
    if (error) {
      const useDocumentError = new FirestoreError(
        'useDocument failed',
        docRef?.path ?? 'unknown'
      )
      const head = (useDocumentError.stack ?? '').split('\n')[0]
      useDocumentError.stack = `${head}\n${calledStackTrace ?? ''}`
      if (error.code === 'internal') {
        console.debug(useDocumentError)
        console.error('firestore internal error')
      } else {
        console.error(useDocumentError)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  return [data, loading, error] as const
}

export const useDocumentOrNull = <T extends DocumentData>(
  docRef: DocumentReference<T> | undefined
) => {
  const [snapshot, loading, error] = useRFHDocument<T>(docRef)
  return [
    snapshot === undefined
      ? undefined
      : snapshot.exists
      ? snapshot.data()!
      : null,
    loading,
    error,
  ] as const
}
